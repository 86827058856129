<template>
  <a-form
    :form="form"
    layout="vertical"
    hide-required-mark
    @submit.prevent="handleSubmit"
  >
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Name">
          <a-input
            v-decorator="[
              'name',
              {
                rules: [{ required: true, message: 'Please enter user name' }],
              },
            ]"
            placeholder="Name"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-10" label="Running Server" :colon="false">
          <a-select v-decorator="[
              'runserver',
              {
                rules: [{ required: true, message: 'Please select server' }],
              },
            ]"
            placeholder="Server">
            <a-select-option v-for="v in server_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-10" label="Exchange" :colon="false">
          <a-select v-decorator="[
              'exchange',
              {
                rules: [{ required: true, message: 'Please select exchange' }],
              },
            ]"
            placeholder="Exchange">
            <a-select-option v-for="(e, i) in exchange" :value="e.value" :key="i">{{ e.label }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Description">
          <a-input
            v-decorator="[
              'description',
              {
                rules: [{ required: true, message: 'Please enter Description' }],
              },
            ]"
            placeholder=""
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Wallet Address">
          <a-input
            v-decorator="[
              'apikey',
              {
                rules: [{ required: true, message: 'Please enter Address' }],
              },
            ]"
            placeholder="Address"
          />
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Private Key">
          <a-input
            v-decorator="[
              'secret',
              {
                rules: [{ required: true, message: 'Please enter PrivateKey' }],
              },
            ]"
            placeholder="PrivateKey"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item class="mb-20" label="Token A">
          <a-select
            v-decorator="[
              'tokena',
              {
                rules: [{ required: true, message: 'Please select Token A' }],
              },
            ]"
            placeholder="Token A"
            mode="multiple"
            @change="handleTokenAChange"
          >
            <!-- 기존 Token A 리스트 -->
            <a-select-option
              v-for="(token, index) in tokena_list"
              :key="index"
              :value="token.symbol"
            >
              {{ token.symbol }}
            </a-select-option>

            <!-- '직접 입력' Option 추가 -->
            <a-select-option value="custom-input-tokena">
              {{ isCustomInputVisible ? "입력중.." : "직접 입력" }}
            </a-select-option>
          </a-select>
          <a-input
            v-if="isCustomInputVisible"
            v-model="customTokenAInput"
            placeholder="직접 입력할 Token 값을 입력하세요"
            @keyup.enter.prevent="addCustomTokenA"
          />

          <template v-if="isCustomInputVisible && !customTokenAInput.trim()">
            <p style="color: red; margin-top: 5px;">값을 입력해 주세요.</p>
          </template>
        </a-form-item>
      </a-col>
      <a-col :span="12">
        <a-form-item class="mb-20" label="Token B">
          <a-select v-decorator="[
              'tokenb',
              {
                rules: [{ required: true, message: 'Please select Token B' }],
              },
            ]"
            placeholder="Token B"
            mode="multiple"
            @change="handleTokenBChange"
          >
            <!-- 기존 Token B 리스트 -->
            <a-select-option
              v-for="(token, index) in tokenb_list"
              :key="index"
              :value="token.symbol"
            >
              {{ token.symbol }}
            </a-select-option>

            <!-- '직접 입력' Option 추가 -->
            <a-select-option value="custom-input-tokenb">
              {{ isCustomInputVisibleB ? "입력중.." : "직접 입력" }}
            </a-select-option>
          </a-select>
          <a-input
            v-if="isCustomInputVisibleB"
            v-model="customTokenBInput"
            placeholder="직접 입력할 Token 값을 입력하세요"
            @keyup.enter.prevent="addCustomTokenB"
          />

          <template v-if="isCustomInputVisibleB && !customTokenBInput.trim()">
            <p style="color: red; margin-top: 5px;">값을 입력해 주세요.</p>
          </template>
        </a-form-item>
      </a-col>
    </a-row>
<!--   <template>-->
<!--       <a-row :gutter="16">-->
<!--           <a-col>-->
<!--               <a-button @click="toggleImage">{{ isImageVisible ? '접기' : 'API 입력 가이드' }}</a-button>-->
<!--               <transition name="fade">-->
<!--                   <img v-if="isImageVisible" src="./API_rule.png" alt="api_rule" class="responsive-image">-->
<!--               </transition>-->
<!--           </a-col>-->
<!--       </a-row>-->
<!--   </template>-->

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        type="primary"
        size="small"
        align="center"
        @click="handleSubmit"
      >
        CREATE
      </a-button>
    </div>
  </a-form>
</template>

<script>
export default {
  data() {
    return {
      exchange: [
        
      ],
      currency_list: [

      ],
      server_list: [
        {
            label: 'Master',
            value: 'master',
        },
        {
            label: 'LP1',
            value: 'lp1',
        },
        {
            label: 'LP2',
            value: 'lp2',
        },
        {
            label: 'LP3',
            value: 'lp3',
        },
        {
            label: 'LP4',
            value: 'lp4',
        },
        {
            label: 'LP5',
            value: 'lp5',
        },
        {
            label: 'LP6',
            value: 'lp6',
        },
        {
            label: 'Dev1',
            value: 'lp7',
        },
        {
            label: 'Dev2',
            value: 'lp8',
        },
      ],
      tokena_list: [
        {
          address: "So11111111111111111111111111111111111111112",
          name: "Wrapped SOL",
          symbol: "SOL",
          decimal: 9,
        },
        {
          address: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
          name: "Ether (Portal)",
          symbol: "ETH",
          decimal: 8,
        },
        {
          address: "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
          name: "Binance Coin (Portal)",
          symbol: "BNB",
          decimal: 8,
        },
        {
          address: "AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z",
          name: "AVAX (Allbridge from Avalanche)",
          symbol: "AVAX",
          decimal: 9,
        },
        {
          address: "J9nsngni1Pavf4ijP4R9QBaD1yEzKzzUQ1vVgcDQT18J",
          name: "Polygone on SOL",
          symbol: "POLYGONE",
          decimal: 5,
        },
      ],
      tokenb_list: [
        {
          address: "So11111111111111111111111111111111111111112",
          name: "Wrapped SOL",
          symbol: "SOL",
          decimal: 9,
        },
        {
          address: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
          name: "Ether (Portal)",
          symbol: "ETH",
          decimal: 8,
        },
        {
          address: "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
          name: "Binance Coin (Portal)",
          symbol: "BNB",
          decimal: 8,
        },
        {
          address: "AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z",
          name: "AVAX (Allbridge from Avalanche)",
          symbol: "AVAX",
          decimal: 9,
        },
        {
          address: "J9nsngni1Pavf4ijP4R9QBaD1yEzKzzUQ1vVgcDQT18J",
          name: "Polygone on SOL",
          symbol: "POLYGONE",
          decimal: 5,
        },
      ],
      is_tokena_loading: true,
      is_tokenb_loading: true,
      tokena: [],
      tokenb: [],
      // isImageVisible: false,
      isCustomInputVisible: false, // Token A 직접 입력 필드 표시 여부
      customTokenAInput: "", // 사용자가 직접 입력한 Token 값
      isCustomInputVisibleB: false, // Token B 직접 입력 필드 표시 여부
      customTokenBInput: "", // 직접 입력한 Token B 값
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: "walletaddlist" });
  },
  async mounted() {
    await this.getExchange()
  },
  methods: {
    // toggleImage() {
    //     this.isImageVisible = !this.isImageVisible;
    // },
    handleTokenAChange(selectedSymbols) {
      this.isCustomInputVisible = selectedSymbols.includes('custom-input-tokena');

      const selectedTokens = this.tokena_list.filter(token =>
        selectedSymbols.includes(token.symbol)
      );

      this.tokena = [
        ...this.tokena.filter(token => token.address.startsWith('custom-')),
        ...selectedTokens,
      ];

      this.form.setFieldsValue({
        tokena: selectedSymbols, // 현재 선택된 심볼
      });
    },

    async addCustomTokenA() {
      if (this.customTokenAInput.trim()) {
        const runserverValue = this.form.getFieldValue('runserver');
        const exchangeValue = this.form.getFieldValue('exchange');
        const inputValue = this.customTokenAInput;

        const res = await this.$http.get(`/api/v1/exchange2/${exchangeValue}/symbols/${runserverValue}/${inputValue}`);
        const result = res.data;

        let customToken = { address: "", symbol: "", name: "", decimal: 0 };
        if (result.statusCode === 200){
          customToken.address = result.data.address;
          customToken.symbol = result.data.symbol;
          customToken.name = result.data.name;
          customToken.decimal = result.data.decimal;
        } else {
          customToken.address = this.customTokenAInput;
          customToken.symbol = result.errorMessage;
          customToken.name = this.customTokenAInput;
          customToken.decimal = 0;
        }

        if (!this.tokena.some(token => token.symbol === customToken.symbol)) {
          this.tokena = [...this.tokena, customToken];
        }

        const selectedSymbols = this.form.getFieldValue('tokena');
        this.form.setFieldsValue({
          tokena: [
            ...selectedSymbols.filter(symbol => symbol !== 'custom-input-tokena'), // '직접 입력' 제거
            customToken.symbol, // 새로 추가된 값
          ],
        });

        this.customTokenAInput = "";
        this.isCustomInputVisible = false;
      }
    },
    handleTokenBChange(selectedSymbols) {
      this.isCustomInputVisibleB = selectedSymbols.includes('custom-input-tokenb');

      const selectedTokens = this.tokenb_list.filter(token =>
        selectedSymbols.includes(token.symbol)
      );

      this.tokenb = [
        ...this.tokenb.filter(token => token.address.startsWith('custom-')),
        ...selectedTokens,
      ];

      this.form.setFieldsValue({
        tokenb: selectedSymbols, // 현재 선택된 심볼
      });
    },
    async addCustomTokenB() {
      if (this.customTokenBInput.trim()) {
        const runserverValue = this.form.getFieldValue('runserver');
        const exchangeValue = this.form.getFieldValue('exchange');
        const inputValue = this.customTokenBInput;

        const res = await this.$http.get(`/api/v1/exchange2/${exchangeValue}/symbols/${runserverValue}/${inputValue}`);
        const result = res.data;

        let customToken = { address: "", symbol: "", name: "", decimal: 0 };
        if (result.statusCode === 200){
          customToken.address = result.data.address;
          customToken.symbol = result.data.symbol;
          customToken.name = result.data.name;
          customToken.decimal = result.data.decimal;
        } else {
          customToken.address = this.customTokenBInput;
          customToken.symbol = result.errorMessage;
          customToken.name = this.customTokenBInput;
          customToken.decimal = 0;
        }

        if (!this.tokenb.some(token => token.symbol === customToken.symbol)) {
          this.tokenb = [...this.tokenb, customToken];
        }

        const selectedSymbols = this.form.getFieldValue('tokenb');
        this.form.setFieldsValue({
          tokenb: [
            ...selectedSymbols.filter(symbol => symbol !== 'custom-input-tokenb'), // '직접 입력' 제거
            customToken.symbol, // 새로 추가된 값
          ],
        });

        this.customTokenBInput = "";
        this.isCustomInputVisibleB = false;
      }
    },
    async getExchange() {
        try {
            const res = await this.$http.get('/api/v1/info/dex_exchange')
            for (const [key, value] of Object.entries(res.data)) {
                this.exchange.push({
                    label: key,
                    value
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        // console.log(values)
        if (!err) {
          // console.log("Received values of form: ", values);
          this.$http
            .post(`/api/v1/wallets/`, {
              name: values.name,
              exchange: values.exchange.toLowerCase(),
              api_key: values.apikey,
              secret_key: values.secret,
              running_server: values.runserver,
              description: values.description,
              tokena: this.tokena,
              tokenb: this.tokenb,
            })
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      this.$router.go();
      // this.$router.push(`/api/v1/apikeys`);
    },
  },
};
</script>

<style>

</style>