<template>
    <div>
        <a-row :gutter="24" type="flex" align="stretch">
            <a-col :span="24" class="mb-5">
                <a-select
                    style="width: 200px; margin-right: 10px"
                    placeholder="Select Wallet"
                    :loading="is_wallet_loading"
                    @change="handleSelectApikeyChange"
                >
                    <a-select-option v-for="v in api_key_list" :key="v.id" :value="v.id">
                        {{v.name}}
                    </a-select-option>
                </a-select>

                <a-select
                    style="width: 200px; margin-right: 10px"
                    placeholder="Select Token A"
                    showSearch
                    @change="handleTokenAChange"
                >
                    <a-select-option v-for="(v, i) in s_token_lista" :key="i" :value="v">
                        {{v}}
                    </a-select-option>
                </a-select>

                <a-select
                    style="width: 200px; margin-right: 10px"
                    placeholder="Select Token B"
                    showSearch
                    @change="handleTokenBChange"
                >
                    <a-select-option v-for="(v, i) in s_token_listb" :key="i" :value="v">
                        {{v}}
                    </a-select-option>
                </a-select>

                <a-input
                    style="width: 200px; margin-right: 10px"
                    placeholder="Binding Server"
                    :value="w_select_server"
                    disabled>
                </a-input>
            </a-col>

            <template v-if="select_wallet_id && selected_token_a && selected_token_b">
              <a-divider />
              <div style="display: flex; justify-content: space-between;">
                  <div style="flex: 1; margin-right: 10px;">
                      <balance
                          ref="balance_ref"
                          :tokena="tokenAData"
                          :tokenb="tokenBData"
                          :wallet_address="w_select_add"
                          :wallet_key="select_wallet_id"
                          :running_server="w_select_server"/>
                  </div>
              </div>

              <a-divider />
              <div style="display: flex; justify-content: space-between;">
                  <div style="flex: 1; margin-right: 10px;">
                      <swap
                          ref="swap_ref"
                          :tokena="tokenAData"
                          :tokenb="tokenBData"
                          :wallet_key="select_wallet_id"
                          :running_server="w_select_server"/>
                  </div>
              </div>

              <a-divider />
              <div style="display: flex; justify-content: space-between;">
                  <div style="flex: 1; margin-right: 10px;">
                      <orders
                          ref="orders_ref"
                          :wallet_key="select_wallet_id"
                          :running_server="w_select_server"/>
                  </div>
              </div>
            </template>
        </a-row>

<!--        <template v-if="select_wallet_id && selected_token_a && selected_token_b">-->
<!--          <a-divider />-->
<!--              <div style="display: flex; justify-content: space-between;">-->
<!--                  <div style="flex: 1; margin-right: 10px;">-->
<!--                      <orders-->
<!--                          ref="orders_ref"-->
<!--                          :wallet_key="select_wallet_id"-->
<!--                          :running_server="w_select_server"/>-->
<!--                  </div>-->
<!--              </div>-->
<!--        </template>-->
    </div>
</template>

<script>
import balance from "../../Dex/Jupiter/balance.vue";
import orders from "../../Dex/Jupiter/orders.vue";
import swap from "../../Dex/Jupiter/swap.vue";

const ExchangeName = 'jupiter';

export default {
    components: {
      balance,
      orders,
      swap,
    },
    data() {
        return {
            is_wallet_loading: true,
            is_pair_loading: true,
            api_key_list: [],
            w_select_server: '', // 선택된 지갑 바인딩 서버
            w_select_add: '',    // 선택된 지갑 주소
            w_select_secret: '', // 선택된 지갑 시크릿키
            w_token_lista: [],   // 선태된 지갑 토큰 a
            w_token_listb: [],   // 선택된 지갑 토큰 b
            s_token_lista: [],   // w_token_lista에서 심볼만 배열로
            s_token_listb: [],   // w_token_listb에서 심볼만 배열로
            selected_token_a: null, // 선택된 토큰 A
            selected_token_b: null, // 선택된 토큰 B
            select_wallet_id: null,
            tokenAData: null,       // 선택된 토큰 A 원래 토큰리스트에서 token A 전체 정보
            tokenBData: null        // 선택된 토큰 B 원래 토큰리스트에서 token B 전체 정보
        }
    },
    methods: {
        handleTokenAChange(value) {
           this.selected_token_a = value;
           this.tokenAData = this.w_token_lista.find(token => token.symbol === value);
        },
        handleTokenBChange(value) {
           this.selected_token_b = value;
           this.tokenBData = this.w_token_listb.find(token => token.symbol === value);
        },
        handleSelectApikeyChange(value) {
           this.select_wallet_id = value;
           const wallets = this.api_key_list.filter((v) => v.id === value);
           console.log(wallets)
           if (wallets.length > 0) {
               this.w_select_server = wallets[0].running_server;
               this.w_select_add = wallets[0].api_key;
               this.w_select_secret = wallets[0].secret_key;
               this.w_token_lista = wallets[0].tokena || [];
               this.w_token_listb = wallets[0].tokenb || [];
               this.s_token_lista = this.w_token_lista.map((token) => token.symbol) || [];
               this.s_token_listb = this.w_token_listb.map((token) => token.symbol) || [];
               this.selected_token_a = null;
               this.selected_token_b = null;
           } else {
               this.w_select_server = '';
               this.w_select_add = '';
               this.w_select_secret = '';
               this.w_token_lista = [];
               this.w_token_listb = [];
               this.s_token_lista = [];
               this.s_token_listb = [];
               this.selected_token_a = null;
               this.selected_token_b = null;
           }
        },
        async get_apikeys(){
            const res = await this.$http.get('/api/v1/wallets/')
            this.is_wallet_loading = false
            return res.data
        },
        async init(){
            const apikey_list = await this.get_apikeys()
            apikey_list.forEach(data => {
                if(data.exchange === ExchangeName){
                    this.api_key_list.push(data)
                }
            })
        }
    },
    async mounted() {
        this.init()
    },
    
}
</script>
<style lang="">
    
</style>