<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{ padding: 0 }">
            <a-table
                :columns="columns"
                :data-source="pagedData"
                :row-key="record => record.signature"
                :pagination="pagination"
                :expandedRowRender="expandedRowRender"
                :expandedRowKeys="expandedRowKeys"
                @expand="handleExpand"
            >
                <template slot="action" slot-scope="text, record">
                    <div
                        class="action-icon"
                        @click="toggleExpand(record)"
                    >
                        ⋮
                    </div>
                </template>
            </a-table>
        </a-card>
    </div>
</template>

<script>
export default {
    props: {
        wallet_key: String,
        running_server: String,
    },
    data() {
        return {
            columns: [
                {
                    title: "Block Time",
                    dataIndex: "timestamp",
                    key: "timestamp",
                    width: "200px",
                },
                {
                    title: "Signature",
                    dataIndex: "signature_",
                    key: "signature_",
                    width: "200px",
                },
                {
                    title: "Details",
                    key: "action",
                    width: "100px",
                    scopedSlots: { customRender: "action" },
                },
            ],
            orders_table: [],
            currentPage: 1,
            pageSize: 10,
            expandedRowKeys: [],
            isReversed: false,
        };
    },
    computed: {
        pagedData() {
            if (this.orders_table.length === 0) {
                return [];
            }
            const start = (this.currentPage - 1) * this.pageSize;
            const end = this.currentPage * this.pageSize;
            return this.orders_table.slice(start, end);
        },
        pagination() {
            return {
                current: this.currentPage,
                pageSize: this.pageSize,
                total: Math.max(1, this.orders_table.length),
                onChange: this.handlePageChange,
                showQuickJumper: true,
            };
        },
    },
    methods: {
        getKoreanTime(timestamp) {
            const date = new Date(timestamp * 1000);
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const hours = String(date.getHours()).padStart(2, "0");
            const minutes = String(date.getMinutes()).padStart(2, "0");
            const seconds = String(date.getSeconds()).padStart(2, "0");
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        },
        async UpdateOrders() {
            const res = await this.$http.post(`/api/v1/exchange2/jupiter/signature`, {
                walletId: this.wallet_key,
                running_server: this.running_server,
            });
            const origin_data = res.data;
            if (origin_data.length > 0) {
                origin_data.forEach(item => {
                    item.timestamp = this.getKoreanTime(item.blockTime);
                    item.signature_ = item.signature.substring(0, 15) + " ...";

                    // 기존 레코드가 있으면 txdetail 유지
                    const existingRecord = this.orders_table.find(record => record.signature === item.signature);
                    item.txdetail = existingRecord ? existingRecord.txdetail : null;
                    item.isReversed = existingRecord ? existingRecord.isReversed : false;
                });
            }
            this.orders_table = origin_data;
        },
        async toggleExpand(record) {
            const index = this.expandedRowKeys.indexOf(record.signature);

            if (index > -1) {
                // 키 제거 (축소)
                this.expandedRowKeys.splice(index, 1);
            } else {
                // 확장 상태
                this.expandedRowKeys.push(record.signature);

                // txdetail이 없을 경우에만 API 호출
                if (!record.txdetail) {
                    await this.fetchDetails(record);
                }
            }
        },
        async fetchDetails(record) {
            try {
                const response = await this.$http.post('/api/v1/exchange2/jupiter/txdetail', {
                    signature: record.signature, // 전달할 signature
                    running_server: this.running_server,
                });
                console.log(response.data)
                // 결과 성공
                if (response.data && response.data.statusCode === 200){
                  this.$set(record, 'txdetail', {
                    swap: response.data.swap,
                    blockTime: response.data.blockTime,
                    fee: response.data.fee,
                    swap_info: response.data.swap_info,
                  });
                } else {
                  this.$set(record, 'txdetail', {
                      swap: false,
                      errorMessage: response.data.errorMessage || ' Error fetching swap details.',
                  });
                }
            } catch (error) {
              // 실패 시 기본값 설정
              this.$set(record, 'txdetail', {
                swap: false,
                errorMessage: error.message || ' Unexpected error occurred.',
              });
            }
        },
        handlePageChange(page) {
            this.currentPage = page;
        },
        handleExpand(expanded, record) {
            if (expanded) {
                this.expandedRowKeys.push(record.signature);
            } else {
                const index = this.expandedRowKeys.indexOf(record.signature);
                if (index > -1) this.expandedRowKeys.splice(index, 1);
            }
        },
        toggleRate(record) {
            this.$set(record, "isReversed", !record.isReversed);
        },
        expandedRowRender(record) {
            const txdetail = record.txdetail;

            if (!txdetail) {
                return (
                    <div class="expanded-details">
                        <p>Loading details...</p>
                    </div>
                );
            }

            return (
                <div class="expanded-details">
                    <p>
                        <strong>Signature:</strong> {record.signature}
                    </p>
                    <p>
                        <strong>Swap Activities:</strong>
                        {txdetail.swap ? (
                            <div class="swap-success">
                                <div class="swap-card">
                                    <span>
                                        <strong class="text-blue">
                                            {txdetail.swap_info.input_amt} {txdetail.swap_info.input_sym} ➔{" "}
                                            {txdetail.swap_info.output_amt} {txdetail.swap_info.output_sym}
                                        </strong>
                                    </span>

                                    {/* Conversion Rate */}
                                    <div class="conversion-rate">
                                        <span>
                                            1{" "}
                                            {record.isReversed
                                                ? txdetail.swap_info.output_sym
                                                : txdetail.swap_info.input_sym}{" "}
                                            ≈{" "}
                                            {record.isReversed
                                                ? txdetail.swap_info.output2input
                                                : txdetail.swap_info.input2output}{" "}
                                            {record.isReversed
                                                ? txdetail.swap_info.input_sym
                                                : txdetail.swap_info.output_sym}
                                        </span>
                                        <button
                                            onClick={() => this.toggleRate(record)}
                                            class="toggle-button"
                                        >
                                            ⇄
                                        </button>
                                    </div>

                                    <div>
                                      <span>fee: {txdetail.fee} SOL</span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <span class="error-message"> Not Found</span>
                        )}
                    </p>
                </div>
            );
        },
    },
    async mounted() {
        await this.UpdateOrders();
        this.updateInterval = setInterval(() => {
            this.UpdateOrders();
        }, 10 * 1000);
    },
    beforeDestroy() {
        clearInterval(this.updateInterval);
    },
};
</script>

<style lang="scss">
.action-icon {
    cursor: pointer;
    font-size: 18px;
    color: #ccc;
    padding: 5px;
}

.action-icon:hover {
    color: #888;
}

.expanded-details {
    padding: 10px;
    background: #f9f9f9;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
}

.swap-success .swap-card {
    margin-top: 0.5em; /* <p> 높이의 1/2 정도 여백 */
    background: #f2f2f2;
    border: 1px solid #e0e0e0;
    padding: 10px;
    border-radius: 5px;
}

.error-message {
    color: red;
    font-weight: bold;
}

.text-blue {
    color: #007bff; /* 파란색 */
}

.conversion-rate {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #333;
}

.conversion-rate span {
    margin-right: 8px; /* 텍스트와 버튼 간 간격 */
}

.toggle-button {
    border: none;
    background: none;
    color: #007bff; /* 파란색 */
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
}

.toggle-button:hover {
    color: #0056b3; /* 더 진한 파란색 */
}
</style>