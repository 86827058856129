<template>
  <div>
    <a-card
      :bordered="false"
      class="header-solid mb-24"
      :bodyStyle="{ padding: 0, paddingTop: '0' }"
    >
      <template #title>
        <a-row type="flex" :gutter="24">
          <a-col><h5 class="font-semibold">Wallet 관리</h5></a-col>
        </a-row>
      </template>

      <div class="mx-15" style="padding-top: 0px">
        <a-row type="flex" :gutter="24">
          <a-col :span="24" :md="12">
            <a-button
              class="px-15"
              size="small"
              type="primary"
              @click="onCreate"
            >
              <a-icon type="plus" />Create New Wallet
            </a-button>
          </a-col>
          <a-col :span="24" :md="12" class="text-right">
            <a-input-search
              id="ant-input-placeholder"
              placeholder="input search text"
              :style="{
                textAlign: 'center',
                maxWidth: '200px',
              }"
              v-model="query"
              @change="onSearchChange"
            />
          </a-col>
        </a-row>
      </div>

      <a-table
        class="mt-20"
        :columns="columns"
        :data-source="table_data"
        :rowKey="(record) => record.id"
        :row-selection="rowSelection"
        :customRow="getSelectedRowKeys"
        :pagination="false"
        :loading="loading"
      >
          <!-- Token A,B 심볼 렌더링 -->
        <template slot="currencies" slot-scope="text">
          <a-tag v-for="(v, i) in text" :key="i">{{v}}</a-tag>
        </template>

        <template slot="action" slot-scope="text, record">
          <a-button @click.native="onEdit(record)" class="px-20" size="small" type="link">
            EDIT
          </a-button>
        </template>
      </a-table>
    </a-card>

    <a-drawer
      title="Create a new account"
      :width="720"
      :visible="isCreate"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <UserCreate />
    </a-drawer>

    <a-drawer
      title="Update account"
      :width="720"
      :visible="isEdit"
      :body-style="{ paddingBottom: '80px' }"
      @close="onClose"
    >
      <UserEdit :updateData="updateData" />
    </a-drawer>
  </div>
</template>

<script>
const columns = [
  // {
  //   key: "id",
  //   title: "ID",
  //   dataIndex: "id",
  //   sorter: (a, b) => a.id - b.id,
  //   sortDirections: ["descend", "ascend"],
  // },
  {
    title: "NAME",
    dataIndex: "name",
    sorter: (a, b) => a.name.length - b.name.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "EXCHANGE",
    dataIndex: "exchange",
    // sorter: (a, b) => a.email.length - b.email.length,
    sortDirections: ["descend", "ascend"],
  },
  {
    title: "ADDRESS",
    dataIndex: "api_key",
  },
  {
    title: "Server",
    dataIndex: "running_server",
  },
  {
    title: "Token A",
    dataIndex: "tokena_symbols",
    scopedSlots: { customRender: "currencies" },
  },
  {
    title: "Token B",
    dataIndex: "tokenb_symbols",
    scopedSlots: { customRender: "currencies" },
  },
  {
    title: "Description",
    dataIndex: "description",
  },
  {
    title: "ACTION",
    align: "center",
    scopedSlots: { customRender: "action" },
  },
];

let data = [];
let tempData = [];

import UserCreate from "./Create.vue";
import UserEdit from "./Edit.vue";

export default {
  components: { UserCreate, UserEdit },
  data() {
    return {
      columns,
      table_data: [],
      pageSize: 10,
      query: "",
      updateData: {},
      selectedRowKeys: [],
      isCreate: false,
      isEdit: false,
      loading: false,
    };
  },

  mounted() {
    this.initData();
  },
  computed: {
    rowSelection() {
      return {
        selections: true,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys;
          // console.log(
          //   `selectedRowKeys: ${selectedRowKeys}`,
          //   "selectedRows: ",
          //   selectedRows
          // );
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.disabled,
          },
        }),
      };
    },
  },
  watch: {
    selectedRowKeys() {
      // tempData = this.data.filter((row) => {
      //   for (const key in row) {
      //     if (row[key].toString().includes(this.selectedRowKeys)) return true;
      //     return false;
      //   }
      // });
      // this.updateData = tempData[0];
      // console.log(this.updateData);
    },
  },
  methods: {
    async initData() {
      this.loading = true;

      await this.$http.get('/api/v1/wallets/', {}).then((res) => {
        this.loading = false;
        console.log(res.data)
        // data = res.data;
        this.table_data = res.data.map((item) => {
          return {
            ...item,
            tokena_symbols: item.tokena.map((token) => token.symbol), // tokena의 모든 심볼 추출
            tokenb_symbols: item.tokenb.map((token) => token.symbol), // tokenb의 모든 심볼 추출
          };
        });
      });
      // console.log(this.table_data);
    },
    onCreate() {
      this.isCreate = true;
    },
    onEdit(row) {
      this.isEdit = true;
      this.updateData = row;
    },
    onClose() {
      this.isCreate = false;
      this.isEdit = false;
    },
    onPageSizeChange() {
      this.pageSize = parseInt(this.pageSize);
    },
    onSearchChange() {
      if (this.query.length > 0) {
        this.data = data.filter((row) => {
          for (const key in row) {
            if (
              row[key]
                .toString()
                .toLowerCase()
                .includes(this.query.trim().toLowerCase())
            )
              return true;
          }
          return false;
        });
      } else {
        this.data = data;
      }
    },
    getSelectedRowKeys(record, index) {
      return {
        on: {
          click: (event) => {
            this.selectedRowKeys = record.id;
          },
        },
      };
    },
  },
};
</script>

<style lang="scss"></style>
