<template>
  <div>
    <!-- 바깥쪽 카드 -->
    <a-card :bordered="false" class="header-solid" :bodyStyle="{padding: 0,}">

      <div class="inner-card-container">
        <!-- 위쪽 카드 -->
        <a-card :bordered="true" class="upper-card">
            <div class="upper-card-content">
              <!-- Selling 텍스트 -->
              <p class="selling-text"> Selling</p>

              <!-- TokenA 심볼 -->
              <p class="token-text">{{ tokenASymbol }}</p>

              <!-- 수량 입력창 -->
              <input type="text" v-model="inputAmt" placeholder="0.00" class="amount-input" />
            </div>
        </a-card>

        <!-- 중앙 원 추가 -->
        <div class="circle" @click="swapTokens">
          <span class="vertical-arrow">⇄</span>
        </div>

        <!-- 아래쪽 카드 -->
        <a-card :bordered="true" class="lower-card">
          <div class="lower-card-content">
            <p class="buying-text"> Buying</p>

              <!-- TokenB 심볼 -->
              <p class="token-text">{{ tokenBSymbol }}</p>

              <!-- 수량 환산창 -->
                <input
                  type="text"
                  v-model="outputAmt"
                  placeholder="0.00"
                  class="amount-output"
                  readonly
                  :class="{ 'error-message': isError }"
                />
          </div>
        </a-card>
      </div>

      <div class="outer-card-title">
        <div class="rate-info">
          Rate
          <div class="rate-converter" v-if="rate_in2out !== '' || rate_out2in !== ''">
            <span>
              1 {{ isRateInToOut ? input_sym : output_sym }} ≈
              {{ isRateInToOut ? rate_in2out : rate_out2in }}
              {{ isRateInToOut ? output_sym : input_sym }}
            </span>
            <span @click="swapRate" class="rate-swap-arrow">⇄</span>
          </div>
        </div>

        <div class="fee-info">
          Fee
          <div class="fee-detail" v-if="rate_in2out !== '' || rate_out2in !== ''">
            <span>{{ quoteFee }} SOL</span>
          </div>
        </div>
      </div>

      <!-- 스왑 버튼 -->
      <div class="swap-card-container">
        <a-card :bordered="true" class="swap-card" @click="submitSwap">
          <p>SWAP</p>
        </a-card>
      </div>

    </a-card>
  </div>
</template>

<script>
export default {
  props: {
      tokena: Object,
      tokenb: Object,
      wallet_key: String,
      running_server: String,
  },
  data() {
    return {
      tokenASymbol: this.tokena.symbol, // Token A의 초기값
      tokenBSymbol: this.tokenb.symbol, // Token B의 초기값
      isCurrentlyTokenA: true,
      inputAmt: '',
      outputAmt: '0.00',
      isError: false,
      rate_in2out: '',
      rate_out2in: '',
      quoteFee: '',
      input_sym: '',
      output_sym: '',
      isRateInToOut: true,
    };
  },
  methods: {
    swapRate() {
      this.isRateInToOut = !this.isRateInToOut;
    },
    roundToEight(value) {
      if (!value) return 0; // null 또는 undefined 값 방지
      return parseFloat(parseFloat(value).toFixed(8));
    },
    swapTokens() {
      // tokenASymbol과 tokenBSymbol 값을 교환
      const tempSymbol = this.tokenASymbol;
      this.tokenASymbol = this.tokenBSymbol;
      this.tokenBSymbol = tempSymbol;
      this.isCurrentlyTokenA = !this.isCurrentlyTokenA;
    },
    async submitSwap() {
        this.input_sym = this.isCurrentlyTokenA ? this.tokena.symbol : this.tokenb.symbol;
        this.output_sym = this.isCurrentlyTokenA ? this.tokenb.symbol : this.tokena.symbol;

        const input_mint = this.isCurrentlyTokenA ? this.tokena.address : this.tokenb.address;
        const output_mint = this.isCurrentlyTokenA ? this.tokenb.address : this.tokena.address;
        const input_decimal = this.isCurrentlyTokenA ? this.tokena.decimal : this.tokenb.decimal;
        const output_decimal = this.isCurrentlyTokenA ? this.tokenb.decimal : this.tokena.decimal;
        const slippage = 50;
        const restrict = true;
        const input_amt = Math.round(this.inputAmt * Math.pow(10, input_decimal));

        const response = await this.$http.post('/api/v1/exchange2/jupiter/swap', {
              inputMint: input_mint,
              outputMint: output_mint,
              inputDeci: input_decimal,
              outputDeci: output_decimal,
              amount: input_amt,
              slippage: slippage,
              restrict: restrict,
              running_server: this.running_server,
              walletId: this.wallet_key,
        });
        const swap = response.data;

        if (swap.statusCode === 200) {
          this.$notification.open({
              message: `Send Transaction - Success`,
              description: `Signature: ${swap.signature_tx}`,
              placement: 'bottomLeft',
          });
        } else {
          this.$notification.open({
              message: `Send Transaction - Fail`,
              description: `error: ${swap.errorMessage}`,
              placement: 'bottomLeft',
          });
        }

    },
    async UpdateQuote() {
        this.input_sym = this.isCurrentlyTokenA ? this.tokena.symbol : this.tokenb.symbol;
        this.output_sym = this.isCurrentlyTokenA ? this.tokenb.symbol : this.tokena.symbol;

        const input_mint = this.isCurrentlyTokenA ? this.tokena.address : this.tokenb.address;
        const output_mint = this.isCurrentlyTokenA ? this.tokenb.address : this.tokena.address;
        const input_decimal = this.isCurrentlyTokenA ? this.tokena.decimal : this.tokenb.decimal;
        const output_decimal = this.isCurrentlyTokenA ? this.tokenb.decimal : this.tokena.decimal;
        const slippage = 50;
        const restrict = true;
        const input_amt = Math.round(this.inputAmt * Math.pow(10, input_decimal));

        const response = await this.$http.post('/api/v1/exchange2/jupiter/quote', {
              inputMint: input_mint,
              outputMint: output_mint,
              inputDeci: input_decimal,
              outputDeci: output_decimal,
              amount: input_amt,
              slippage: slippage,
              restrict: restrict,
              running_server: this.running_server,
        });
        const quote = response.data;
        // console.log(quote);

        if (quote.statusCode === 400) {
          this.isError = true;
          this.outputAmt = quote.errorMessage;
        } else {
          this.isError = false;
          if (this.inputAmt === '' || this.inputAmt === '0') {
            this.outputAmt = '';
            this.rate_in2out = '';
            this.rate_out2in = '';
            this.quoteFee = '';
          } else {
            this.outputAmt = quote.outputAmt;
            this.rate_in2out = this.roundToEight(quote.input2output);
            this.rate_out2in = this.roundToEight(quote.output2input);
            this.quoteFee = quote.feeAmount;
          }
        }


    },
  },
  async mounted() {
    await this.UpdateQuote();
    this.updateInterval = setInterval(() => {
        this.UpdateQuote();
    }, 20 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  }
};
</script>

<style lang="scss" scoped>
.header-solid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 490px;
  height: 350px; /* 320 */
  position: relative;
}

.outer-card-title {
  display: flex;
  flex-direction: column; /* 수직 배치 */
  justify-content: flex-start; /* 좌우로 Rate와 Fee 배치 */
  align-items: flex-start; /* 세로축 정렬 */
  margin-top: 10px;
  padding: 0 5px; /* 좌우 패딩 */
  font-size: 18px; /* 글자 크기 */
  font-weight: bold; /* 글자 두께 */
  box-sizing: border-box; /* 패딩 포함해 정렬 */
}

.rate-info {
  margin-bottom: 10px; /* Fee와 간격 조정 */
  color: #666;
  text-align: start; /* 텍스트 왼쪽 정렬 */
}

.fee-info{
  color: #666; /* Rate 색상 */
  text-align: start; /* 텍스트 중앙 정렬 */
}

.inner-card-container {
  display: flex;
  flex-direction: column; /* 세로로 배치 */
  justify-content: center; /* 세로 중심 정렬 */
  align-items: center; /* 가로 중심 정렬 */
  width: 450px; /* 안쪽 카드의 폭 설정 */
  height: 200px; /* 안쪽 카드의 높이 설정 */
  position: relative; /* 중앙 원의 위치 조정을 위한 relative 설정 */
  margin-top: 20px;
}

.upper-card {
  position: relative;
  height: 50%; /* 위쪽 카드 높이 */
  width: 100%; /* 위쪽 카드 폭 */
  border-bottom-left-radius: 0; /* 아래쪽 모서리 둥글기 제거 */
  border-bottom-right-radius: 0; /* 아래쪽 모서리 둥글기 제거 */
  background-color: #e0e0e0;
  display: flex;
}

.upper-card-content,
.lower-card-content {
  display: flex; /* Flexbox 정렬 사용 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  height: 100%; /* 부모 요소 높이에 맞춤 */
  width: 100%; /* 부모 요소 너비에 맞춤 */
  position: relative; /* 콘텐츠 내부 정렬 제어 */
}

.selling-text,
.buying-text {
  font-size: 14px; /* 텍스트 크기 */
  color: #333; /* 텍스트 색상 */
  position: absolute; /* 위치를 고정 */
  left: 0px;
  top: 0px;
}

.token-text {
  position: absolute; /* 위치를 고정 */
  white-space: nowrap; /* 텍스트 줄바꿈 금지 */
  overflow: hidden;    /* 넘치는 텍스트 숨김 */
  text-overflow: ellipsis; /* 텍스트가 넘칠 경우 생략 ... 표시 (선택 사항) */
  left: 0px; /* Selling 텍스트에서 오른쪽으로 20px 간격 */
  top: 34px;
  width: auto;
  display: flex; /* 내부 텍스트 정렬용 */
  font-size: 25px; /* 카드 안의 글자 크기 */
  font-weight: bold; /* 텍스트 두께 */
  color: #333; /* 텍스트 색상 */
}

.amount-input {
  position: absolute; /* 위치를 고정 */
  width: 300px;
  height: 50px;
  left: 120px;
  top: 10px;
  background-color: transparent;
  display: flex; /* 내부 텍스트 정렬용 */
  text-align: right;
  font-size: 26px; /* 카드 안의 글자 크기 */
  font-weight: bold; /* 텍스트 두께 */
  color: #333; /* 텍스트 색상 */
  border: none;
  outline: none;
}

.amount-output {
  position: absolute; /* 위치를 고정 */
  width: 300px;
  height: 50px;
  left: 120px;
  top: 12.5px;
  background-color: transparent;
  display: flex; /* 내부 텍스트 정렬용 */
  text-align: right;
  font-size: 26px; /* 카드 안의 글자 크기 */
  font-weight: bold; /* 텍스트 두께 */
  color: #333; /* 텍스트 색상 */
  border: none;
  outline: none;
}

.amount-output.error-message {
  color: red; /* 에러일 때 빨간색 텍스트 */
  font-size: 20px; /* 텍스트 크기를 조금 줄임 */
}

.lower-card {
  position: relative;
  height: 50%; /* 아래쪽 카드 높이 */
  width: 100%; /* 아래쪽 카드 폭 */
  border-top-left-radius: 0; /* 위쪽 모서리 둥글기 제거 */
  border-top-right-radius: 0; /* 위쪽 모서리 둥글기 제거 */
  background-color: #f0f0f0;
  display: flex;
}

.circle {
  position: absolute;
  top: 50%; /* 수직 중앙으로 위치 */
  left: 50%; /* 수평 중앙으로 위치 */
  transform: translate(-50%, -50%); /* 정확히 가운데 정렬 */
  width: 40px; /* 원의 너비 */
  height: 40px; /* 원의 높이 */
  background-color: #e0e0e0; /* 원 배경색 */
  border-radius: 50%; /* 원 모양 */
  border: 2px solid #ffffff; /* 흰색 테두리 */
  z-index: 10; /* 카드 위로 나오도록 */
  display: flex; /* 플렉스 박스 사용 */
  justify-content: center; /* 가로 중앙 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  transition: border-color 0.3s ease; /* 테두리 색상이 부드럽게 변하도록 트랜지션 추가 */
  cursor: pointer;
}

.circle:hover {
  border-color: lightgreen; /* 마우스 커서가 올려졌을 때 테두리를 연두색으로 변경 */
}

.vertical-arrow {
  font-size: 20px; /* 화살표 크기 */
  font-weight: bold; /* 텍스트 두께 */
  color: #000; /* 화살표 색상 */
  transform: rotate(90deg); /* 화살표를 90도 회전 (세로로) */
}

.circle:hover .vertical-arrow {
  color: lightgreen; /* 마우스 커서가 올려졌을 때 테두리를 연두색으로 변경 */
}

.swap-card-container {
  display: flex;
  justify-content: center;
  margin-top: 10px; /* inner-card-container과의 간격 */
  width: 450px;
  cursor: pointer;
  margin-bottom: 20px;
}

.swap-card {
  width: 100%;
  height: 50px; /* 새 카드 높이 */
  background: linear-gradient(to right, skyblue, lightgreen);
  text-align: center;
  font-size: 24px; /* 글자 크기 */
  font-weight: bold; /* 글자 두께 */
  color: #666;
}

.swap-card:hover {
  /* 마우스 오버 시 효과 */
  opacity: 0.9; /* 살짝 어두워짐 */
}

.rate-converter {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: #333; /* 색 조정 */
  margin-left: 5px; /* Rate 옆 간격 조정 */
}

.rate-swap-arrow {
  cursor: pointer;
  margin-left: 8px;
  font-weight: bold;
  color: #000; /* 화살표 색상 */
  transition: transform 0.2s;
}

.rate-swap-arrow:hover {
  color: lightgreen; /* hover 효과 */
}

.fee-detail {
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  color: #333; /* 색 조정 */
  margin-left: 5px; /* Rate 옆 간격 조정 */
}
</style>