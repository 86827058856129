<template>
    <div>
        <a-card :bordered="false" class="header-solid h-full" :bodyStyle="{padding: 0,}">
			      <a-table :columns="columns"
                     :data-source="balance_table"
                     :row-key="record => record.transfer_date"
                     :pagination="false"
                     :showHeader="true"
                     :loading="loading">
            </a-table>
		</a-card>
    </div>
</template>
<script>
const Exchange = 'jupiter'
export default {
    props: {
        tokena: Object,
        tokenb: Object,
        wallet_address: String,
        wallet_key: String,
        running_server: String,
    },
    components: {

    },
    data() {
        return {
            columns: [
                {
                    title: () => {
                        return (
                            <span style="text-align: center; display: block;">
                                Address<br />(지갑 주소)
                            </span>
                        );
                    },
                    dataIndex: 'address',
                    align: 'center', // 가운데 정렬
                },
                {
                    title: () => {
                        return (
                            <span style="text-align: center; display: block;">
                                SOL Balance<br />(기준 네트워크)
                            </span>
                        );
                    },
                    dataIndex: 'sol',
                    align: 'center', // 가운데 정렬
                },
                {
                    title: `${this.tokena.symbol} Balance`,
                    dataIndex: 'tokena',
                },
                {
                    title: `${this.tokenb.symbol} Balance`,
                    dataIndex: 'tokenb',
                },
            ],
            balance_table: [
            ],
            loading: true,
        }
    },
    methods: {
        async UpdateBalance(){
            // 디버깅용 ---------------------------------
            // console.log(this.tokena)
            // console.log(this.tokenb)
            // console.log(this.wallet_key)

            const res = await this.$http.post(`/api/v1/exchange2/${Exchange}/balance`, {
                walletId: this.wallet_key,
                running_server: this.running_server,
                tokena_mint: this.tokena.address,
                tokenb_mint: this.tokenb.address,
            })

            const balance = res.data;
            this.balance_table[0].sol = balance.solBalance;

            if (this.tokena.symbol.toUpperCase() === "SOL"){
              this.balance_table[0].tokena = balance.solBalance;
            } else {
              this.balance_table[0].tokena = balance.tokenA;
            }

            if (this.tokenb.symbol.toUpperCase() === "SOL"){
              this.balance_table[0].tokenb = balance.solBalance;
            } else {
              this.balance_table[0].tokenb = balance.tokenB;
            }
        },
        init_balance(){
            this.balance_table = []
            this.balance_table.push({
                address: this.wallet_address,
                sol: 0,
                tokena: 0,
                tokenb: 0
            })
        },
        setPriceFormat(v){
            return Number(v).toLocaleString(undefined, { maximumFractionDigits: 5 })
        },
        updateColumns() {
            // columns 값을 갱신
            this.columns = [
                {
                    title: () => {
                        return (
                            <span style="text-align: center; display: block;">
                                Address<br />(지갑 주소)
                            </span>
                        );
                    },
                    dataIndex: "address",
                    align: "center",
                },
                {
                    title: () => {
                        return (
                            <span style="text-align: center; display: block;">
                                SOL Balance<br />(기준 네트워크)
                            </span>
                        );
                    },
                    dataIndex: "sol",
                    align: "center",
                },
                {
                    title: `${this.tokena.symbol} Balance`,
                    dataIndex: "tokena",
                },
                {
                    title: `${this.tokenb.symbol} Balance`,
                    dataIndex: "tokenb",
                },
            ];
        },
    },
    watch: {
        "tokena.symbol": "updateColumns",
        "tokenb.symbol": "updateColumns",
    },
    async mounted() {
        this.init_balance()
        await this.UpdateBalance()
        this.loading = false
        this.updateInterval = setInterval(() => {
            this.UpdateBalance()

        }, (10 * 1000))
    },
    beforeDestroy() {
        clearInterval(this.updateInterval)
    },
}
</script>

<style lang="scss">

</style>