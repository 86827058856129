<template>
    <div>
        <!-- choice apikey, exchange, coin, pair -->
        <a-form-model
          ref="ruleForm"
          :model="configData"
          :rules="rules"
          :label-col="{span: 4}"
          :wrapper-col="{span: 20}"
          
          layout="horizontal"
        >
            <a-form-model-item ref="name" label="Name" prop="name">
                <a-input
                    v-model="configData.name"
                    size="small"
                    style="width: 60%"
                />
            </a-form-model-item>

            <a-form-model-item label="Exchange" prop="name">
                <a-select
                    v-model="configData.exchange"
                    size="small"
                    style="width: 40%"
                    @change="changeExchangeSelect"
                >
                    <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <template v-if="configData.exchange">
                <a-form-model-item label="Apikey" prop="name">
                    <a-select
                        v-model="configData.apikey"
                        size="small"
                        style="width: 40%"
                        :loading="is_apikey_loading"
                        @change="handleApikeyChange"
                    >
                        <a-select-option v-for="v in apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Coin" prop="name">
                    <a-select
                        v-model="configData.coin"
                        size="small"
                        style="width: 40%"
                        :loading="is_symbols_loading"
                        showSearch
                    >
                        <a-select-option v-for="v in symbols_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Pair" prop="name">
                    <a-select
                        v-model="configData.pair"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in pair_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
            </template>
        
        </a-form-model>
        <a-divider/>
        <!-- <template v-if="configData.exchange && configData.coin && configData.pair"> -->
        <template>
            <!-- config -->
            <a-form-model
                :model="configDetailData"
                :rules="rules"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                layout="horizontal">

                <template v-for="form in config_form">
                    <a-form-model-item :label="form.label" prop="name" :key="form.value">
                        <template v-if="form.type === 'number'">
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :min="0"
                                :max="100"
                                :formatter="value => `${value}%`"
                                :parser="value => value.replace('%', '')"
                                v-if="form.filter === 'percent'"
                            />
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                v-else
                            />
                        </template>
                        <template v-if="form.type === 'boolean'">
                            <a-switch
                                v-model="configDetailData[form.value]"
                            />
                        </template>
                    </a-form-model-item>
                </template>
                <!-- 스케줄 테이블 설정 -->
                <a-divider/>
                <a-form-model-item :label="$t('scheduler.scheduler_table')">
                    <template v-if="setableScheduleTable">
                        <a-button size="small"  @click="openScheduleTableModal(true)" :loading="schedule_table_loading">open</a-button>
                        <template v-if="configDetailData.schedule_table || is_schedule_table_setting">
                            <span class="ml-25">{{scheduleTableLength}}</span>
                        </template>
                        <template v-else>
                            <span class="ml-25">Empty</span>
                        </template>
                    </template>
                </a-form-model-item>
                <a-form-model-item :label="$t('scheduler.telegram1')" prop="name">
                    <a-select
                        v-model="select_telegram_id"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item :label="$t('scheduler.telegram2')" prop="name">
                    <a-select
                        v-model="select_telegram_id2"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="Select On/Off" prop="name">
                    <a-switch
                        v-model="telegram_select_active"
                    ></a-switch>
                </a-form-model-item>

            </a-form-model>
            
            <a-row>
                <a-col>
                    <span>{{ error_log }}</span>
                </a-col>
                <a-col>
                    <a-button class="m-15" @click="emitBtn">{{ is_edit? 'Modify' : 'Add' }}</a-button>
                </a-col>
            </a-row>
        </template>
        <a-modal
            v-model:visible="scheduleTableModalVisible"
            title="chart"
            width="1500px"
            :forceRender="true"
            :footer="null"
            >
            <scheduler-table-modal ref="scheduleTableChartModal" @result="getScheduleTable"/>
        </a-modal>
    </div>
</template>
<script>
import schedulerTableModal from './schedulerTableModal.vue'
export default {
  components: { schedulerTableModal },
    data() {
        return {
            configData: {},
            configDetailData: {},
            rules: {},
            exchange_list:
            [
            
            ],
            coin_list:
            [
                {
                    label: 'klaytn',
                    value: 'klay'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            pair_list:
            [
                {
                    label: 'KRW',
                    value: 'krw'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            config_form: [
                {
                    label: this.$i18n.t("scheduler.schedule_time"),
                    value: 'schedule_time',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.change_cycle"),
                    value: 'change_cycle',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.change_percent"),
                    value: 'change_percent',
                    filter: 'percent',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.trend"),
                    value: 'trend',
                    filter: 'percent',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.brick_break_cycle"),
                    value: 'brick_break_cycle',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.order_buy_amount_my_limit"),
                    value: 'order_buy_amount_my_limit',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.order_sell_amount_my_limit"),
                    value: 'order_sell_amount_my_limit',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.order_buy_amount_out_limit"),
                    value: 'order_buy_amount_out_limit',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.order_sell_amount_out_limit"),
                    value: 'order_sell_amount_out_limit',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.order_strategy"),
                    value: 'order_strategy',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.target_max_price"),
                    value: 'target_max_price',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.target_min_price"),
                    value: 'target_min_price',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("scheduler.coin_protect"),
                    value: 'coin_protect',
                    type: 'number',
                    required: false
                },
                {
                    label: this.$i18n.t("scheduler.pair_protect"),
                    value: 'pair_protect',
                    type: 'number',
                    required: false
                },
                // {
                //     label: this.$i18n.t("scheduler.is_monitoring"),
                //     value: 'is_monitoring',
                //     type: 'boolean',
                //     required: false
                // },
            ],
            telegram_list: [],
            select_telegram_id: null,
            select_telegram_id2: null,
            symbols_list: [],
            apikey_list: [],
            is_apikey_loading: true,
            is_symbols_loading: true,
            schedule_table_loading: false,
            error_log: '',
            is_edit: false,
            scheduleTableModalVisible: false,
            is_schedule_table_setting: false,
            stand_price: 0,
            apikey_list2: [],
            select_server: null,
            telegram_select_active: false
        }
    },
    async mounted() {
        await this.getExchange()
        await this.getTelegram()
    },
    computed: {
        setableScheduleTable() {
            return this.configDetailData.schedule_time && this.configDetailData.change_cycle 
            && this.configDetailData.change_percent && this.configDetailData.trend &&
            this.configDetailData.target_max_price && this.configDetailData.target_min_price;
        },
        scheduleTableLength() {
            return this.configDetailData.schedule_table.length
        }
    },
    methods: {
        async getTelegram() {
            try {
                const res = await this.$http.get('/api/v1/telegram/')
                for (const o of res.data) {
                    this.telegram_list.push({
                        label: o.name,
                        value: o.id
                    })
                }
                // console.log(this.telegram_list)
            } catch (e) {
                console.error(e)
            }
        },
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        getScheduleTable(schedule_table) {
            this.configDetailData.schedule_table = schedule_table
            this.is_schedule_table_setting = true
        },
        async openScheduleTableModal( value ) {
            if (this.configDetailData.schedule_time && this.configDetailData.change_cycle &&
                                                        this.configDetailData.change_percent && this.configDetailData.trend &&
                                                        this.configDetailData.target_max_price && this.configDetailData.target_min_price) {
                // console.log(this.configDetailData)
                this.schedule_table_loading = true
                await this.getTicker()
                this.$refs.scheduleTableChartModal.setProps(this.configDetailData.schedule_time, this.configDetailData.change_cycle,
                                                            this.configDetailData.change_percent, this.configDetailData.trend,
                                                            this.stand_price, this.configDetailData.schedule_table,
                                                            this.configDetailData.target_min_price, this.configDetailData.target_max_price)
                this.schedule_table_loading = false                        
                // this.$refs.scheduleTableChartModal.changeData()
                this.scheduleTableModalVisible = value
            }
        },
        // 수정
        async getTicker(){
            let symbol = ''
            if (this.configData.exchange === 'coinstore'){
                symbol = `${this.configData.coin}${this.configData.pair}`;
            } else {
                symbol = `${this.configData.coin}_${this.configData.pair}`; 
            }
            const res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/ticker/${this.select_server}/${symbol.toUpperCase()}`);

            if (this.configData.exchange === 'bitget' || this.configData.exchange === 'coinstore') {
                this.stand_price = res.data.close;
            } else if (this.configData.exchange === 'gopax' || this.configData.exchange === 'lbank') {
                this.stand_price = res.data.price;
            } else if (this.configData.exchange === 'whitebit') {
                this.stand_price = res.data.last_price;
            } else if (this.configData.exchange === 'xt') {
                this.stand_price = res.data.c;
            } else if (this.configData.exchange === 'upbit') {
                this.stand_price = res.data.trade_price;
            } else if (this.configData.exchange === 'mexc' || this.configData.exchange === 'bingx') {
                this.stand_price = res.data.lastPrice;
            } else {
                this.stand_price = res.data.last;
            }

        },
        // get apikeys
        async changeExchangeSelect(v){
            
            await this.get_apikeys(v)
            // await this.get_symbols(v)
        },
        async get_apikeys(v){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {
                        label: e.name,
                        value: e.id
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        async handleApikeyChange(value) {
            const selectedApiKey = this.apikey_list2.find(apikey => apikey.id === value);
            if (selectedApiKey) {
                this.select_server = selectedApiKey.running_server;
                await this.get_symbols(this.configData.exchange, this.select_server);
            }
        },
        async get_symbols(exchange, runserver){
            this.symbols_list = []
            this.is_symbols_loading = true
            const res = await this.$http.get(`/api/v1/exchange2/${exchange}/symbols/${runserver}`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                this.pair_list = []
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!this.symbols_list.find(s => s.label === _symbol[0])) {
                        this.symbols_list.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!this.pair_list.find(s => s.label === _symbol[1])) {
                        this.pair_list.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    this.symbols_list.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
            }
            this.is_symbols_loading = false
            return res.data
        },
        async emitBtn(){
            await this.registScheduler()
        },
        async registScheduler(){
            const scheduler = {
                ...this.configData,
                server: this.select_server,
                telegram_id: this.select_telegram_id,
                telegram_id2: this.select_telegram_id2,
                telegram_select: this.telegram_select_active,
                config: this.configDetailData
            }
            try{
                if (this.is_edit === true){
                    const res = await this.$http.put(`/api/v1/autotrade/scheduler/${this.configData._id}`, scheduler)
                    this.error_log = ''
                } else {
                    const res = await this.$http.post('/api/v1/autotrade/scheduler/', scheduler)
                    this.error_log = ''
                }
                this.$router.go();
            } catch (e){
                console.error(e.response)
                this.error_log = e.response.data.message
            }
        },
        createEdit(record){
            this.is_edit = true
            this.configData = record
            this.configDetailData = record.config
            this.select_server = record.server
            this.select_telegram_id = record.telegram_id
            this.select_telegram_id2 = record.telegram_id2
            this.telegram_select_active = record.telegram_select
            this.changeExchangeSelect(record.exchange)
        },
        createAdd(){
            if(this.is_edit === true) {
                this.is_edit = false
                this.clearForm()
            }
        },
        clearForm(){
            this.configData = {}
            this.configDetailData = {}
        }

    },
    
}
</script>
<style lang="scss">

.ant-form-item {
    margin-bottom: 5px;
}
    
</style>