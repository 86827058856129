<template>
  <a-form-model
      ref="ruleForm"
      :model="updateData"
      layout="vertical"
      @submit.prevent="onSubmit"
      @keydown.enter.prevent.stop
  >
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Name">
          <a-input v-model="updateData.name" />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="Running Server">
          <a-select v-model="updateData.running_server">
            <a-select-option v-for="v in server_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
    </a-row>
    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item label="Exchange">
          <a-select v-model="updateData.exchange">
            <a-select-option v-for="(e, i) in exchange" :value="e.value" :key="i">{{ e.label }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Description">
          <a-input v-model="updateData.description" />
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Wallet Address">
          <a-input
            v-model="edit_api_key"
          />
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item class="mb-20" label="Private Key">
          <a-input
            v-model="edit_secret_key"
          />
        </a-form-model-item>
      </a-col>
    </a-row>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-model-item label="Token A">
          <a-select
              v-model="selectedTokenSymbols.tokena"
              mode="multiple"
              @change="handleTokenAChange"
          >
            <!-- 기존 Token A 리스트 -->
            <a-select-option
              v-for="(token, index) in tokena_list"
              :key="index"
              :value="token.symbol"
            >
              {{ token.symbol }}
            </a-select-option>
            <!-- '직접 입력' Option 추가 -->
            <a-select-option value="custom-input-tokena">
              {{ isCustomInputVisible ? "입력중.." : "직접 입력" }}
            </a-select-option>
          </a-select>
          <a-input
            v-if="isCustomInputVisible"
            v-model="customTokenAInput"
            placeholder="직접 입력할 Token 값을 입력하세요"
            @keydown.enter.prevent.stop="addCustomTokenA"
          />

          <template v-if="isCustomInputVisible && !customTokenAInput.trim()">
            <p style="color: red; margin-top: 5px;">값을 입력해 주세요.</p>
          </template>
        </a-form-model-item>
      </a-col>
      <a-col :span="12">
        <a-form-model-item label="Token B">
          <a-select
              v-model="selectedTokenSymbols.tokenb"
              mode="multiple"
              @change="handleTokenBChange"
          >
            <a-select-option
              v-for="(token, index) in tokenb_list"
              :key="index"
              :value="token.symbol"
            >
              {{ token.symbol }}
            </a-select-option>
            <!-- '직접 입력' Option 추가 -->
            <a-select-option value="custom-input-tokenb">
              {{ isCustomInputVisibleB ? "입력중.." : "직접 입력" }}
            </a-select-option>
          </a-select>
          <a-input
            v-if="isCustomInputVisibleB"
            v-model="customTokenBInput"
            placeholder="직접 입력할 Token 값을 입력하세요"
            @keydown.enter.prevent.stop="addCustomTokenB"
          />

          <template v-if="isCustomInputVisibleB && !customTokenBInput.trim()">
            <p style="color: red; margin-top: 5px;">값을 입력해 주세요.</p>
          </template>
        </a-form-model-item>
      </a-col>
    </a-row>

<!--    <template>-->
<!--      <a-row :gutter="16">-->
<!--          <a-col>-->
<!--              <a-button @click="toggleImage">{{ isImageVisible ? '접기' : 'API 입력 가이드' }}</a-button>-->
<!--              <transition name="fade">-->
<!--                  <img v-if="isImageVisible" src="./API_rule.png" alt="api_rule" class="responsive-image">-->
<!--              </transition>-->
<!--          </a-col>-->
<!--      </a-row>-->
<!--   </template>-->

    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '10px 25px',
        background: '#fff',
        textAlign: 'right',
      }"
    >
      <a-button
        class="px-15"
        :style="{ marginRight: '5px' }"
        type="primary"
        size="small"
        html-type="submit"
        @click="onSubmit"
      >
        MODIFY
      </a-button>
      <a-button class="px-15" type="danger" size="small" @click="onDelete">
        DELETE
      </a-button>
    </div>
  </a-form-model>
</template>

<script>
export default {
  props: ["updateData"],
  data() {
    return {
      exchange: [
        
      ],
      currency_list: [

      ],
      server_list: [
        {
            label: 'Master',
            value: 'master',
        },
        {
            label: 'LP1',
            value: 'lp1',
        },
        {
            label: 'LP2',
            value: 'lp2',
        },
        {
            label: 'LP3',
            value: 'lp3',
        },
        {
            label: 'LP4',
            value: 'lp4',
        },
        {
            label: 'LP5',
            value: 'lp5',
        },
        {
            label: 'LP6',
            value: 'lp6',
        },
        {
            label: 'Dev1',
            value: 'lp7',
        },
        {
            label: 'Dev2',
            value: 'lp8',
        },
      ],
            tokena_list: [
        {
          address: "So11111111111111111111111111111111111111112",
          name: "Wrapped SOL",
          symbol: "SOL",
          decimal: 9,
        },
        {
          address: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
          name: "Ether (Portal)",
          symbol: "ETH",
          decimal: 8,
        },
        {
          address: "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
          name: "Binance Coin (Portal)",
          symbol: "BNB",
          decimal: 8,
        },
        {
          address: "AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z",
          name: "AVAX (Allbridge from Avalanche)",
          symbol: "AVAX",
          decimal: 9,
        },
        {
          address: "J9nsngni1Pavf4ijP4R9QBaD1yEzKzzUQ1vVgcDQT18J",
          name: "Polygone on SOL",
          symbol: "POLYGONE",
          decimal: 5,
        },
      ],
      tokenb_list: [
        {
          address: "So11111111111111111111111111111111111111112",
          name: "Wrapped SOL",
          symbol: "SOL",
          decimal: 9,
        },
        {
          address: "7vfCXTUXx5WJV5JADk17DUJ4ksgau7utNKj4b963voxs",
          name: "Ether (Portal)",
          symbol: "ETH",
          decimal: 8,
        },
        {
          address: "9gP2kCy3wA1ctvYWQk75guqXuHfrEomqydHLtcTCqiLa",
          name: "Binance Coin (Portal)",
          symbol: "BNB",
          decimal: 8,
        },
        {
          address: "AUrMpCDYYcPuHhyNX8gEEqbmDPFUpBpHrNW3vPeCFn5Z",
          name: "AVAX (Allbridge from Avalanche)",
          symbol: "AVAX",
          decimal: 9,
        },
        {
          address: "J9nsngni1Pavf4ijP4R9QBaD1yEzKzzUQ1vVgcDQT18J",
          name: "Polygone on SOL",
          symbol: "POLYGONE",
          decimal: 5,
        },
      ],
      edit_api_key: '',
      edit_secret_key: '',
      // isImageVisible: false
      selectedTokenSymbols: {
        tokena: [],
        tokenb: [],
      },
      isCustomInputVisible: false, // Token A 직접 입력 필드 표시 여부
      customTokenAInput: "", // 사용자가 직접 입력한 Token 값
      isCustomInputVisibleB: false, // Token B 직접 입력 필드 표시 여부
      customTokenBInput: "", // 직접 입력한 Token B 값
    };
  },
  computed: {
  },
  async mounted() {
    await this.getExchange()
    if (this.updateData.exchange){
    }
    this.selectedTokenSymbols.tokena = this.updateData.tokena.map((t) => t.symbol);
    this.selectedTokenSymbols.tokenb = this.updateData.tokenb.map((t) => t.symbol);
  },
  methods: {
    // toggleImage() {
    //     this.isImageVisible = !this.isImageVisible;
    // },
    async getExchange() {
        try {
            const res = await this.$http.get('/api/v1/info/dex_exchange')
            for (const [key, value] of Object.entries(res.data)) {
                this.exchange.push({
                    label: key,
                    value
                })
            }
        } catch (e) {
            console.error(e)
        }
    },
    handleTokenAChange(selectedSymbols) {
      // "직접 입력" 필드가 활성화되어야 하는지 확인
      this.isCustomInputVisible = selectedSymbols.includes('custom-input-tokena');

      // 선택된 토큰 중, 커스텀 데이터 유지
      const customTokens = this.updateData.tokena.filter((token) =>
        token.address.startsWith("custom-") && selectedSymbols.includes(token.symbol)
      );

      // 새로운 심볼에 해당하는 토큰 데이터 가져오기
      const newTokens = this.tokena_list.filter((token) =>
        selectedSymbols.includes(token.symbol)
      );

      // 선택되지 않은 커스텀 토큰은 즉시 제거
      this.updateData.tokena = [...customTokens, ...newTokens];

      // 선택된 심볼만 유지
      this.selectedTokenSymbols.tokena = selectedSymbols;
    },
    async addCustomTokenA() {
      if (this.customTokenAInput.trim()) {
        const runserverValue = this.updateData.running_server;
        const exchangeValue = this.updateData.exchange.toLowerCase();
        const inputValue = this.customTokenAInput;

        const res = await this.$http.get(`/api/v1/exchange2/${exchangeValue}/symbols/${runserverValue}/${inputValue}`);
        const result = res.data;

        let customToken = { address: "", symbol: "", name: "", decimal: 0 };
        if (result.statusCode === 200){
          customToken.address = result.data.address;
          customToken.symbol = result.data.symbol;
          customToken.name = result.data.name;
          customToken.decimal = result.data.decimal;
        } else {
          customToken.address = this.customTokenAInput;
          customToken.symbol = result.errorMessage;
          customToken.name = this.customTokenAInput;
          customToken.decimal = 0;
        }

        if (!this.updateData.tokena.some(token => token.symbol === customToken.symbol)) {
          this.updateData.tokena.push(customToken);
        }

        this.selectedTokenSymbols.tokena = [
          ...this.selectedTokenSymbols.tokena.filter(symbol => symbol !== 'custom-input-tokena'),
          customToken.symbol,
        ];

        this.customTokenAInput = ""; // 입력값 초기화
        this.isCustomInputVisible = false; // 입력 필드 숨김
      }
    },
    handleTokenBChange(selectedSymbols) {
      // "직접 입력" 필드가 활성화되어야 하는지 확인
      this.isCustomInputVisibleB = selectedSymbols.includes('custom-input-tokenb');

      // 선택된 토큰 중, 커스텀 데이터 유지
      const customTokens = this.updateData.tokenb.filter((token) =>
        token.address.startsWith("custom-") && selectedSymbols.includes(token.symbol)
      );

      // 새로운 심볼에 해당하는 토큰 데이터 가져오기
      const newTokens = this.tokenb_list.filter((token) =>
        selectedSymbols.includes(token.symbol)
      );

      // 선택되지 않은 커스텀 토큰은 즉시 제거
      this.updateData.tokenb = [...customTokens, ...newTokens];

      // 선택된 심볼만 유지
      this.selectedTokenSymbols.tokenb = selectedSymbols;
    },
    async addCustomTokenB() {
      if (this.customTokenBInput.trim()) {
        const runserverValue = this.updateData.running_server;
        const exchangeValue = this.updateData.exchange.toLowerCase();
        const inputValue = this.customTokenBInput;

        const res = await this.$http.get(`/api/v1/exchange2/${exchangeValue}/symbols/${runserverValue}/${inputValue}`);
        const result = res.data;

        let customToken = { address: "", symbol: "", name: "", decimal: 0 };
        if (result.statusCode === 200){
          customToken.address = result.data.address;
          customToken.symbol = result.data.symbol;
          customToken.name = result.data.name;
          customToken.decimal = result.data.decimal;
        } else {
          customToken.address = this.customTokenBInput;
          customToken.symbol = result.errorMessage;
          customToken.name = this.customTokenBInput;
          customToken.decimal = 0;
        }

        if (!this.updateData.tokenb.some(token => token.symbol === customToken.symbol)) {
          this.updateData.tokenb.push(customToken);
        }

        this.selectedTokenSymbols.tokenb = [
          ...this.selectedTokenSymbols.tokenb.filter(symbol => symbol !== 'custom-input-tokenb'),
          customToken.symbol,
        ];

        this.customTokenBInput = ""; // 입력값 초기화
        this.isCustomInputVisibleB = false; // 입력 필드 숨김
      }
    },
    get_edit_data() {
      const data = {
        id: this.updateData.id,
        name: this.updateData.name,
        exchange: this.updateData.exchange.toLowerCase(),
        running_server: this.updateData.running_server,
        description: this.updateData.description,
        tokena: this.updateData.tokena,
        tokenb: this.updateData.tokenb,
      };

      // API Key 및 Secret Key 조건부 추가
      if (this.edit_api_key) {
        data.api_key = this.edit_api_key;
      }
      if (this.edit_secret_key) {
        data.secret_key = this.edit_secret_key;
      }

      return data;
    },
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // console.log("Received values of form: ", this.user);
          this.$http
            .put(`/api/v1/wallets/${this.updateData.id}`, this.get_edit_data())
            .then(() => {
              this.redirect();
            });
        } else {
          console.log(err);
        }
      });
    },
    onDelete() {
      this.$http
        .delete(`/api/v1/wallets/${this.updateData.id}`, {
          // params: {
          //   id: this.updateData.id,
          // },
        })
        .then(() => {
          this.redirect();
        });
    },
    redirect() {
      // TO-DO : redirect error will solved(Completed)
      // this.$router.push("/pages/UserManagement/UserList")
      this.$router.go(0);
      // this.$router.push(`/api/v1/apikeys`);
    },
  },
};
</script>

<style>

</style>
