<template>
    <div>
        <!-- choice apikey, exchange, coin, pair -->
        <a-form-model
          ref="ruleForm"
          :model="configData"
          :rules="rules"
          :label-col="{span: 4}"
          :wrapper-col="{span: 20}"
          
          layout="horizontal"
        >
            <a-form-model-item ref="name" label="Name" prop="name">
                <a-input
                    v-model="configData.name"
                    size="small"
                    style="width: 60%"
                />
            </a-form-model-item>

            <a-form-model-item label="Exchange" prop="name">
                <a-select
                    v-model="configData.exchange"
                    size="small"
                    style="width: 40%"
                    @change="changeExchangeSelect"
                >
                    <a-select-option v-for="v in exchange_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                </a-select>
            </a-form-model-item>
            <template v-if="configData.exchange">
                <a-form-model-item label="Apikey" prop="name">
                    <a-select
                        v-model="configData.apikey"
                        size="small"
                        style="width: 40%"
                        :loading="is_apikey_loading"
                        @change="handleApikeyChange"
                    >
                        <a-select-option v-for="v in apikey_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Coin" prop="name">
                    <a-select
                        v-model="configData.coin"
                        size="small"
                        style="width: 40%"
                        :loading="is_symbols_loading"
                        showSearch
                    >
                        <a-select-option v-for="v in symbols_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Pair" prop="name">
                    <a-select
                        v-model="configData.pair"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in pair_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>
            </template>
        
        </a-form-model>
        <a-divider/>
        <!-- manual -->
        <template v-if="configData.exchange && configData.coin && configData.pair">
            <!-- config -->
            <a-form-model
                :model="configDetailData"
                :rules="rules"
                :label-col="{span: 5}"
                :wrapper-col="{span: 19}"
                layout="horizontal">
                    
                <!-- <a-divider/> -->
                <template v-for="form in config_form">
                    <a-form-model-item :label="form.label" prop="name" :key="form.value">
                        <template v-if="form.type === 'number'">
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :min="0"
                                :max="100"
                                :formatter="value => `${value}%`"
                                :parser="value => value.replace('%', '')"
                                v-if="form.filter === 'percent'"
                            />
                            <a-input-number
                                v-model="configDetailData[form.value]"
                                size="small"
                                style="width: 40%"
                                :formatter="value => {
                                    const parts = value.toString().split('.');
                                    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                                    return parts.join('.');
                                }"
                                :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                                v-else
                            />
                        </template>
                        <template v-if="form.type === 'boolean'">
                            <a-switch
                                v-model="configDetailData[form.value]"
                            />
                        </template>
                    </a-form-model-item>
                </template>
               
                <a-form-model-item :label="$t('fillblank_v2.precision')" prop="name">
                    <a-input-number
                        v-model="precision_price"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v2.telegram1')" prop="name">
                    <a-select
                        v-model="select_telegram_id"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v2.telegram2')" prop="name">
                    <a-select
                        v-model="select_telegram_id2"
                        size="small"
                        style="width: 40%"
                    >
                        <a-select-option v-for="v in telegram_list" :key="v.value" :value="v.value">{{ v.label }}</a-select-option>
                    </a-select>
                </a-form-model-item>

                <a-form-model-item label="Select On/Off" prop="name">
                    <a-switch
                        v-model="telegram_select_active"
                    ></a-switch>
                </a-form-model-item>

                <a-divider/>
                <div style="margin-top: 10px;">* 강제체결 관련(비활성화:-1) *</div>
                <a-form-model-item :label="$t('fillblank_v2.is_opposite_allowance')" prop="name">
                    <a-switch
                        v-model="is_opposite_allowance"
                    ></a-switch>
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v2.opposite_upper_limit')" prop="name">
                    <a-input-number
                        v-model="opposite_upper_limit"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>
                <a-form-model-item :label="$t('fillblank_v2.opposite_lower_limit')" prop="name">
                    <a-input-number
                        v-model="opposite_lower_limit"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>
                <a-form-model-item :label="$t('fillblank_v2.order_buy_amount_out_limit')" prop="name">
                    <a-input-number
                        v-model="order_buy_amount_out_limit"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>
                <a-form-model-item :label="$t('fillblank_v2.order_sell_amount_out_limit')" prop="name">
                    <a-input-number
                        v-model="order_sell_amount_out_limit"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="-1"
                        />
                </a-form-model-item>

                <a-divider/>
                <div style="margin-top: 10px;">* 호가벽은 범위외취소 On일때만 사용가능 *</div>
                <a-form-model-item :label="$t('fillblank_v2.ask_mode_wall')" prop="name">
                    <a-switch
                        v-model="ask_mode_wall"
                    ></a-switch>
                </a-form-model-item>

                <div style="margin-left: 280px; margin-top: 10px;">매도벽 가격 계산</div>
                <a-form-model-item :label="$t('fillblank_v2.ask_mode_range')" prop="name">
                    <a-input-number
                        v-model="ask_mode_range"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        />
                    <a-input-search 
                        v-model="configData.price_to_askwall"
                        style="width: 65%;" 
                        :loading="is_price_to_wall1_loading" 
                        disabled 
                    />
                    <a-button type="link" icon="sync" @click="calcwall1" />                        
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v2.ask_mode_price')" prop="name">
                    <a-input-number
                        v-model="ask_mode_price"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min="0"
                        />
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v2.bid_mode_wall')" prop="name">
                    <a-switch
                        v-model="bid_mode_wall"
                    ></a-switch>
                </a-form-model-item>

                <div style="margin-left: 280px; margin-top: 10px;">매수벽 가격 계산</div>
                <a-form-model-item :label="$t('fillblank_v2.bid_mode_range')" prop="name">
                    <a-input-number
                        v-model="bid_mode_range"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        />
                    <a-input-search 
                        v-model="configData.price_to_bidwall"
                        style="width: 65%;" 
                        :loading="is_price_to_wall2_loading" 
                        disabled 
                    />
                    <a-button type="link" icon="sync" @click="calcwall2" />                        
                </a-form-model-item>

                <a-form-model-item :label="$t('fillblank_v2.bid_mode_price')" prop="name">
                    <a-input-number
                        v-model="bid_mode_price"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        :min=0
                        />
                </a-form-model-item>

                <a-divider/>
                <!-- img -->
                <img src="./manual.png" alt="manual_mode" style="width: 250px; height: 350;" >

                <div style="margin-left: 280px; margin-top: 10px;">기준가격(종가 확인)</div>
                <a-form-model-item :label="$t('fillblank_v2.target_price')" prop="name">
                    <a-input-number
                        v-model="target_price"
                        size="small"
                        style="width: 20%; margin-right: 5%;" 
                        :formatter="value => {
                            const parts = value.toString().split('.');
                            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                            return parts.join('.');
                        }"
                        :parser="value => value.replace(/\$\s?|(,*)/g, '')"
                        />
                    <a-input-search 
                        v-model="configData.stand_price_1"
                        style="width: 65%;" 
                        :loading="is_stand_price_loading" 
                        disabled 
                    />
                    <a-button type="link" icon="sync" @click="getTicker" />
                </a-form-model-item>

                <div style="margin-left: 280px; margin-top: 10px;">시작가격 계산(기준가격 + 갭)</div>
                <a-form-model-item :label="$t('fillblank_v2.target_fill_gap')" prop="name">
                    <a-input-number
                        v-model="target_fill_gap"
                        size="small"
                        style="width: 20%; margin-right: 5%;"
                        :min="0"
                        />
                    <a-input-search 
                        v-model="configData.price_to_gap"
                        style="width: 65%;" 
                        :loading="is_price_to_gap_loading" 
                        disabled 
                    />
                    <a-button type="link" icon="sync" @click="calcGap" />
                </a-form-model-item>
            
                <template>
                    <div>
                        <div style="margin-left: 280px; margin-top: 10px;">매도호가 범위 계산(시작범위 ~ 끝범위)</div>
                        <a-form-model-item :label="$t('fillblank_v2.fill_range_percent_manual_1')" prop="name">
                            <a-input 
                                v-model="newItem1" 
                                size="small"
                                style="width: 20%; margin-right: 5%;"
                                @input="handleInput"
                                placeholder="Start Gap">
                            </a-input>
                            <a-input-search 
                                v-model="configData.price_to_percent1"
                                style="width: 65%;" 
                                :loading="is_price_to_percent1_loading" 
                                disabled 
                            />
                            <a-button type="link" icon="sync" @click="calcPercent1" />
                        </a-form-model-item>

                        <div style="margin-left: 280px; margin-top: 10px;">매수호가 범위 계산(시작범위 ~ 끝범위)</div>
                        <a-form-model-item :label="$t('fillblank_v2.fill_range_percent_manual_2')" prop="name">
                            <a-input 
                                v-model="newItem2" 
                                size="small"
                                style="width: 20%; margin-right: 5%;"
                                @input="handleInput2"
                                placeholder="End Gap">
                            </a-input>
                            <a-input-search 
                                v-model="configData.price_to_percent2"
                                style="width: 65%;" 
                                :loading="is_price_to_percent2_loading" 
                                disabled 
                            />
                            <a-button type="link" icon="sync" @click="calcPercent2" />
                        </a-form-model-item>

                        <div style="margin-left: 280px; margin-top: 10px;"> </div>
                        <a-form-model-item :label="$t('fillblank_v2.price')" prop="name">
                            <a-input 
                                v-model="newItem3" 
                                size="small"
                                style="width: 20%"
                                @input="handleInput3"
                                placeholder="Used">
                            </a-input>
                        </a-form-model-item>

                        <div style="margin-left: 40px; margin-top: 10px;">* 범위 겹치지 않도록 주의 *</div>
                        <a-button type="primary" shape="circle" size="small" style="margin-left: 40px;" @click="addItem" :disabled="!areBothItemsEntered">+</a-button>
                        <div v-for="(pairIndex, index) in Math.floor(items.length)" :key="pairIndex">
                            <div class="item-content">

                                <div style="margin-left: 40px; margin-top: 10px;">
                                    Range {{index+1}}: ({{ items[index][0] }} ~ {{ items[index][1] }}) %, Fill Price: {{ items[index][2] }} {{ configData.pair }}
                                </div>
                                <a-button type="primary" shape="circle" size="small" @click="deleteItem(index)">-</a-button>
                            </div>
                        </div>

                    </div>
                </template>

            </a-form-model>
        </template>

        <a-row>
            <a-col>
                <span>{{ error_log }}</span>
            </a-col>
            <a-col>
                <a-button class="m-15" @click="emitBtn">{{ is_edit? 'Modify' : 'Add' }}</a-button>
            </a-col>
        </a-row>
    </div>
</template>
<script>
export default {
    data() {
        return {
            configData: {},
            configDetailData: {
                is_ask_mode: false,
                is_bid_mode: false,
                is_out_amount_ignore: false
            },
            configOptionPrecisionData: {
                
            },
            rules: {},
            exchange_list:
            [
                
            ],
            coin_list:
            [
                {
                    label: 'klaytn',
                    value: 'klay'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            pair_list:
            [
                {
                    label: 'KRW',
                    value: 'krw'
                },
                // {
                //     label: 'Coinone',
                //     value: 'coinone'
                // },
            ],
            option_form: [
                // {
                //     label: '소수점지정(수량)',
                //     value: 'amount',
                //     type: 'number',
                //     required: false
                // },
            ],
            config_form: [
                {
                    label: this.$i18n.t("fillblank_v2.speed"),
                    value: 'speed',
                    type: 'number',
                    required: true
                },            
                {
                    label: this.$i18n.t("fillblank_v2.amount_min"),
                    value: 'amount_min',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank_v2.amount_max"),
                    value: 'amount_max',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.ask_remain_tick"),
                    value: 'ask_remain_tick',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank.bid_remain_tick"),
                    value: 'bid_remain_tick',
                    type: 'number',
                    required: true
                },
                {
                    label: this.$i18n.t("fillblank_v2.is_ask_mode"),
                    value: 'is_ask_mode',
                    type: 'boolean',
                    required: true
                },                
                {
                    label: this.$i18n.t("fillblank_v2.is_bid_mode"),
                    value: 'is_bid_mode',
                    type: 'boolean',
                    required: true
                },                
                {
                    label: this.$i18n.t("fillblank_v2.is_out_amount_ignore"),
                    value: 'is_out_amount_ignore',
                    type: 'boolean',
                    required: true
                },            
                {
                    label: this.$i18n.t("fillblank_v2.is_cancel_out_range"),
                    value: 'is_cancel_out_range',
                    type: 'boolean',
                    required: true
                },
                // {
                //     label: this.$i18n.t("fillblank_v2.is_opposite_allowance"),
                //     value: 'is_opposite_allowance',
                //     type: 'boolean',
                //     required: true
                // },
                {
                    label: this.$i18n.t("fillblank_v2.coin_protect"),
                    value: 'coin_protect',
                    type: 'number',
                    required: false
                },
                {
                    label: this.$i18n.t("fillblank_v2.pair_protect"),
                    value: 'pair_protect',
                    type: 'number',
                    required: false
                },
                // {
                //     label: this.$i18n.t("fillblank_v2.precision"),
                //     value: 'price',
                //     type: 'number',
                //     required: false,
                // },
                // {
                //     label: this.$i18n.t("fillblank_v2.ask_mode_wall"), 
                //     value: 'ask_mode_wall',
                //     type: 'boolean',
                //     required: false
                // },     
                // {
                //     label: this.$i18n.t("fillblank_v2.ask_mode_range"), 
                //     value: 'ask_mode_range',
                //     type: 'number',
                //     required: false
                // },
                // {
                //     label: this.$i18n.t("fillblank_v2.ask_mode_price"),
                //     value: 'ask_mode_price',
                //     type: 'number',
                //     required: false
                // },
                // {
                //     label: this.$i18n.t("fillblank_v2.bid_mode_wall"),
                //     value: 'bid_mode_wall',
                //     type: 'boolean',
                //     required: false
                // },     
                // {
                //     label: this.$i18n.t("fillblank_v2.bid_mode_range"),
                //     value: 'bid_mode_range',
                //     type: 'number',
                //     required: false
                // },
                // {
                //     label: this.$i18n.t("fillblank_v2.bid_mode_price"),
                //     value: 'bid_mode_price',
                //     type: 'number',
                //     required: false
                // },
            ],
            telegram_list: [],
            select_telegram_id: null,
            select_telegram_id2: null,
            symbols_list: [],
            apikey_list: [],
            is_apikey_loading: true,
            is_symbols_loading: true,
            error_log: '',
            is_edit: false,
            target_price: 0,
            fill_total_price: 0,
            target_fill_gap: 0,
            fill_range_percent: 0,
            newItem1: '',
            newItem2: '', 
            newItem3: '',
            items: [],
            ticker: '',
            is_stand_price_loading: false,
            is_price_to_gap_loading: false,
            is_price_to_percent1_loading: false,
            is_price_to_percent2_loading: false,
            apikey_list2: [],
            select_server: null,
            telegram_select_active: false,
            ask_mode_wall: false,
            ask_mode_range: 0,
            ask_mode_price: 0,
            bid_mode_wall: false,
            bid_mode_range: 0,
            bid_mode_price: 0,
            precision_price: -1,
            is_price_to_wall1_loading: false,
            is_price_to_wall2_loading: false,
            is_opposite_allowance: false,
            opposite_upper_limit: -1,
            opposite_lower_limit: -1,
            order_buy_amount_out_limit: -1,
            order_sell_amount_out_limit: -1,
        }
    },
    async mounted() {
        await this.getExchange()
        await this.getTelegram()
    },
    methods: {
        async getTelegram() {
            try {
                const res = await this.$http.get('/api/v1/telegram/')
                for (const o of res.data) {
                    this.telegram_list.push({
                        label: o.name,
                        value: o.id
                    })
                }
                // console.log(this.telegram_list)
            } catch (e) {
                console.error(e)
            }
        },
        async getExchange() {
            try {
                const res = await this.$http.get('/api/v1/info/exchange')
                for (const [key, value] of Object.entries(res.data)) {
                    this.exchange_list.push({
                        label: key,
                        value
                    })
                }
            } catch (e) {
                console.error(e)
            }
        },
        // get apikeys
        async changeExchangeSelect(v){
            
            await this.get_apikeys(v)
            // await this.get_symbols(v)
        },
        async get_apikeys(v){
            this.apikey_list = []
            this.is_apikey_loading = true
            const res = await this.$http.get('/api/v1/apikeys/', {
                params:{
                    exchange: v
                    }
                })
            res.data.forEach(e => {
                this.apikey_list.push(
                    {
                        label: e.name,
                        value: e.id
                    }
                )
            });
            this.apikey_list2 = res.data
            this.is_apikey_loading = false
        },
        async handleApikeyChange(value) {
            const selectedApiKey = this.apikey_list2.find(apikey => apikey.id === value);
            if (selectedApiKey) {
                this.select_server = selectedApiKey.running_server;
                await this.get_symbols(this.configData.exchange, this.select_server);
            }
        },
        async get_symbols(exchange, runserver){
            this.symbols_list = []
            this.is_symbols_loading = true
            const res = await this.$http.get(`/api/v1/exchange2/${exchange}/symbols/${runserver}`)
            let symbol_split = ''
            if (res.data[0].includes('/')) {
                symbol_split = '/'
            } else if (res.data[0].includes('_')) {
                symbol_split = '_'
            }
            if (symbol_split !== '') {
                this.pair_list = []
                res.data.forEach(e => {
                    const _symbol = e.split(symbol_split)
                    if (!this.symbols_list.find(s => s.label === _symbol[0])) {
                        this.symbols_list.push(
                            {
                                label: _symbol[0],
                                value: _symbol[0].toLowerCase()
                            }
                        )
                    }
                    if (!this.pair_list.find(s => s.label === _symbol[1])) {
                        this.pair_list.push({
                            label: _symbol[1],
                            value: _symbol[1].toLowerCase()
                        })
                    }

                });
            } else {
                res.data.forEach(e => {
                    this.symbols_list.push(
                        {
                            label: e,
                            value: e.toLowerCase()
                        }
                    )
                });
            }
            this.is_symbols_loading = false
            return res.data
        },
        async emitBtn(){
            await this.registCrossTrade()
        },
        async registCrossTrade(){
            const crossTrade = {
                ...this.configData,
                server: this.select_server,
                telegram_id: this.select_telegram_id,
                telegram_id2: this.select_telegram_id2,
                telegram_select: this.telegram_select_active,
                precision_price_: this.precision_price,
                ask_mode_wall_: this.ask_mode_wall,
                ask_mode_range_: this.ask_mode_range,
                ask_mode_price_: this.ask_mode_price,
                bid_mode_wall_: this.bid_mode_wall,
                bid_mode_range_: this.bid_mode_range,
                bid_mode_price_: this.bid_mode_price,
                is_opposite_allowance_: this.is_opposite_allowance,
                opposite_upper_limit_: this.opposite_upper_limit,
                opposite_lower_limit_: this.opposite_lower_limit,
                order_buy_amount_out_limit_: this.order_buy_amount_out_limit,
                order_sell_amount_out_limit_: this.order_sell_amount_out_limit,
                config: {...this.configDetailData, mode: {
                    target_price_: this.target_price,
                    fill_total_price_: this.fill_total_price,
                    target_fill_gap_: this.target_fill_gap,
                    fill_range_percent_: this.fill_range_percent,
                    amounts: this.items,
                }}            
            }
            try{
                if (this.is_edit === true){
                    const res = await this.$http.put(`/api/v1/autotrade/fillblank_v2/${this.configData._id}`, crossTrade)
                    this.error_log = ''
                } else {
                    const res = await this.$http.post('/api/v1/autotrade/fillblank_v2/', crossTrade)
                    this.error_log = ''
                }
                this.$router.go();
            } catch (e){
                console.error(e.response)
                this.error_log = e.response.data.message
            }
        },
        async getTicker(){
            this.is_stand_price_loading = true;
            // getTicker 
            // 심볼
            // Input case1: 'COIN_PAIR' coinstore 제외 나머지 거래소
            // Input case2: 'COINPAIR' coinstore
            // 종가 
            // Output case1: 'last' 나머지 거래소  
            // Output case2: 'close' bitget, coinstore
            // Output case3: 'price' gopax
            // Output case4: 'last_price' whitebit
            // Output case5: 'c' xt
            // 종가 호출 -----------------------------------------------------------------------------------------
            if (this.target_price === -1){
                // 심볼
                let symbol = ''
                if (this.configData.exchange === 'coinstore'){
                    symbol = `${this.configData.coin}${this.configData.pair}`;
                } else {
                    symbol = `${this.configData.coin}_${this.configData.pair}`; 
                }
                // 종가 exchange2로
                const ticker_1 = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/ticker/${this.select_server}/${symbol.toUpperCase()}`);
                // console.log(ticker_1)
                let ticker_last = ''
                if (this.configData.exchange === 'bitget' || this.configData.exchange === 'coinstore') {
                    ticker_last = ticker_1.data.close;
                } else if (this.configData.exchange === 'gopax' || this.configData.exchange === 'lbank') {
                    ticker_last = ticker_1.data.price;
                } else if (this.configData.exchange === 'whitebit') {
                    ticker_last = ticker_1.data.last_price;
                } else if (this.configData.exchange === 'xt') {
                    ticker_last = ticker_1.data.c;
                } else if (this.configData.exchange === 'upbit') {
                    ticker_last = ticker_1.data.trade_price;
                } else if (this.configData.exchange === 'mexc' || this.configData.exchange === 'bingx') {
                    ticker_last = ticker_1.data.lastPrice;
                } else {
                    ticker_last = ticker_1.data.last;
                }
                this.configData.stand_price_1 = `종가: ${ticker_last}`;
            //-----------------------------------------------------------------------------------------------------
            } else {
                this.configData.stand_price_1 = `사용자 지정가: ${this.target_price}`;
            }
            this.is_stand_price_loading = false;
        },
        async calcPercent1(){
            this.is_price_to_percent1_loading = true;
            let base_price = 0
            if (this.target_price === -1){
                //티커 호출 --------------------------------------------------------------------------------------------
                // 심볼
                let symbol = ''
                if (this.configData.exchange === 'coinstore'){
                    symbol = `${this.configData.coin}${this.configData.pair}`;
                } else {
                    symbol = `${this.configData.coin}_${this.configData.pair}`; 
                }
                // 종가 exchange2로
                const ticker_1 = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/ticker/${this.select_server}/${symbol.toUpperCase()}`);

                let ticker_last = ''
                if (this.configData.exchange === 'bitget' || this.configData.exchange === 'coinstore') {
                    ticker_last = ticker_1.data.close;
                } else if (this.configData.exchange === 'gopax' || this.configData.exchange === 'lbank') {
                    ticker_last = ticker_1.data.price;
                } else if (this.configData.exchange === 'whitebit') {
                    ticker_last = ticker_1.data.last_price;
                } else if (this.configData.exchange === 'xt') {
                    ticker_last = ticker_1.data.c;
                } else if (this.configData.exchange === 'upbit') {
                    ticker_last = ticker_1.data.trade_price;
                } else if (this.configData.exchange === 'mexc' || this.configData.exchange === 'bingx') {
                    ticker_last = ticker_1.data.lastPrice;
                } else {
                    ticker_last = ticker_1.data.last;
                }
                base_price = ticker_last;
            //-----------------------------------------------------------------------------------------------------
            } else {
                base_price = this.target_price;
            }

            //오더북 호출 --------------------------------------------------------------------------------------------
            let symbol_ob = '';
            if (this.configData.exchange === 'okex'){
                symbol_ob = `${this.configData.coin.toUpperCase()}-${this.configData.pair.toUpperCase()}`;
            } else if (this.configData.exchange === 'coinstore' || this.configData.exchange === 'huobi' || this.configData.exchange === 'mexc' || this.configData.exchange === 'binance') {
                symbol_ob = `${this.configData.coin.toUpperCase()}${this.configData.pair.toUpperCase()}`
            } else if (this.configData.exchange === 'lbank') {
                symbol_ob = `${this.configData.coin}_${this.configData.pair}`;
            } else if (this.configData.exchange === 'bitkub') {
                symbol_ob = `${this.configData.pair.toUpperCase()}_${this.configData.coin.toUpperCase()}`;
            } else {
                symbol_ob = `${this.configData.coin.toUpperCase()}_${this.configData.pair.toUpperCase()}`;
            }
            
            let res
            if (this.configData.exchange === 'upbit' || this.configData.exchange === 'coinone'){
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}/0`);
            } else {
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}`);
            }
            let res_data = res.data;

            //-----------------------------------------------------------------------------------------------------
            const precision = this.calcOrderbook(res_data);
            const price_level = this.calPriceLevelUnit(base_price, this.target_fill_gap, precision[0], precision[1]);
            const price_percent = this.calPricePercentUnit(price_level, this.newItem1, precision[1]);
            const price_percent_ = this.calPricePercentUnit(price_level, this.newItem2, precision[1]);
            const low = this.calPriceLevelUnit(price_percent, -1, precision[0], precision[1]);
            const high = this.calPriceLevelUnit(price_percent_, 1, precision[0], precision[1]);
            const gap = this.calcGapLevel(low, high, precision[0], precision[1])
            this.configData.price_to_percent1 = `범위(${this.newItem1}~${this.newItem2}) / 가격(${price_percent}~${price_percent_}) / 갭:${gap}`;

            this.is_price_to_percent1_loading = false;
        },
        async calcPercent2(){
            this.is_price_to_percent2_loading = true;
            let base_price = 0
            if (this.target_price === -1){
                //티커 호출 --------------------------------------------------------------------------------------------
                // 심볼
                let symbol = ''
                if (this.configData.exchange === 'coinstore'){
                    symbol = `${this.configData.coin}${this.configData.pair}`;
                } else {
                    symbol = `${this.configData.coin}_${this.configData.pair}`; 
                }
                // 종가 exchange2로
                const ticker_1 = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/ticker/${this.select_server}/${symbol.toUpperCase()}`);

                let ticker_last = ''
                if (this.configData.exchange === 'bitget' || this.configData.exchange === 'coinstore') {
                    ticker_last = ticker_1.data.close;
                } else if (this.configData.exchange === 'gopax' || this.configData.exchange === 'lbank') {
                    ticker_last = ticker_1.data.price;
                } else if (this.configData.exchange === 'whitebit') {
                    ticker_last = ticker_1.data.last_price;
                } else if (this.configData.exchange === 'xt') {
                    ticker_last = ticker_1.data.c;
                } else if (this.configData.exchange === 'upbit') {
                    ticker_last = ticker_1.data.trade_price;
                } else if (this.configData.exchange === 'mexc' || this.configData.exchange === 'bingx') {
                    ticker_last = ticker_1.data.lastPrice;
                } else {
                    ticker_last = ticker_1.data.last;
                }
                base_price = ticker_last;
            //-----------------------------------------------------------------------------------------------------
            } else {
                base_price = this.target_price;
            }

            //오더북 호출 --------------------------------------------------------------------------------------------
            let symbol_ob = '';
            if (this.configData.exchange === 'okex'){
                symbol_ob = `${this.configData.coin.toUpperCase()}-${this.configData.pair.toUpperCase()}`;
            } else if (this.configData.exchange === 'coinstore' || this.configData.exchange === 'huobi' || this.configData.exchange === 'mexc' || this.configData.exchange === 'binance') {
                symbol_ob = `${this.configData.coin.toUpperCase()}${this.configData.pair.toUpperCase()}`
            } else if (this.configData.exchange === 'lbank') {
                symbol_ob = `${this.configData.coin}_${this.configData.pair}`;
            } else if (this.configData.exchange === 'bitkub') {
                symbol_ob = `${this.configData.pair.toUpperCase()}_${this.configData.coin.toUpperCase()}`;
            } else {
                symbol_ob = `${this.configData.coin.toUpperCase()}_${this.configData.pair.toUpperCase()}`;
            }
            
            let res
            if (this.configData.exchange === 'upbit' || this.configData.exchange === 'coinone'){
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}/0`);
            } else {
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}`);
            }
            let res_data = res.data;

            //-----------------------------------------------------------------------------------------------------
            const precision = this.calcOrderbook(res_data);
            const price_level = this.calPriceLevelUnit(base_price, (this.target_fill_gap * -1), precision[0], precision[1]);
            const price_percent = this.calPricePercentUnit(price_level, (this.newItem1 * -1), precision[1]);
            const price_percent_ = this.calPricePercentUnit(price_level, (this.newItem2 * -1), precision[1]);
            const low = this.calPriceLevelUnit(price_percent_, -1, precision[0], precision[1]);
            const high = this.calPriceLevelUnit(price_percent, 1, precision[0], precision[1]);
            const gap = this.calcGapLevel(low, high, precision[0], precision[1])
            this.configData.price_to_percent2 = `범위(${this.newItem1}~${this.newItem2}) / 가격(${price_percent_}~${price_percent}) / 갭:${gap}`;

            this.is_price_to_percent2_loading = false;
        },
        async calcwall1(){
            this.is_price_to_wall1_loading = true;
            let base_price = 0
            if (this.target_price === -1){
                //티커 호출 --------------------------------------------------------------------------------------------
                // 심볼
                let symbol = ''
                if (this.configData.exchange === 'coinstore'){
                    symbol = `${this.configData.coin}${this.configData.pair}`;
                } else {
                    symbol = `${this.configData.coin}_${this.configData.pair}`; 
                }
                // 종가 exchange2로
                const ticker_1 = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/ticker/${this.select_server}/${symbol.toUpperCase()}`);

                let ticker_last = ''
                if (this.configData.exchange === 'bitget' || this.configData.exchange === 'coinstore') {
                    ticker_last = ticker_1.data.close;
                } else if (this.configData.exchange === 'gopax' || this.configData.exchange === 'lbank') {
                    ticker_last = ticker_1.data.price;
                } else if (this.configData.exchange === 'whitebit') {
                    ticker_last = ticker_1.data.last_price;
                } else if (this.configData.exchange === 'xt') {
                    ticker_last = ticker_1.data.c;
                } else if (this.configData.exchange === 'upbit') {
                    ticker_last = ticker_1.data.trade_price;
                } else if (this.configData.exchange === 'mexc' || this.configData.exchange === 'bingx') {
                    ticker_last = ticker_1.data.lastPrice;
                } else {
                    ticker_last = ticker_1.data.last;
                }
                base_price = ticker_last;
            //-----------------------------------------------------------------------------------------------------
            } else {
                base_price = this.target_price;
            }

            //오더북 호출 --------------------------------------------------------------------------------------------
            let symbol_ob = '';
            if (this.configData.exchange === 'okex'){
                symbol_ob = `${this.configData.coin.toUpperCase()}-${this.configData.pair.toUpperCase()}`;
            } else if (this.configData.exchange === 'coinstore' || this.configData.exchange === 'huobi' || this.configData.exchange === 'mexc' || this.configData.exchange === 'binance') {
                symbol_ob = `${this.configData.coin.toUpperCase()}${this.configData.pair.toUpperCase()}`
            } else if (this.configData.exchange === 'lbank') {
                symbol_ob = `${this.configData.coin}_${this.configData.pair}`;
            } else if (this.configData.exchange === 'bitkub') {
                symbol_ob = `${this.configData.pair.toUpperCase()}_${this.configData.coin.toUpperCase()}`;
            } else {
                symbol_ob = `${this.configData.coin.toUpperCase()}_${this.configData.pair.toUpperCase()}`;
            }
            
            let res
            if (this.configData.exchange === 'upbit' || this.configData.exchange === 'coinone'){
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}/0`);
            } else {
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}`);
            }
            let res_data = res.data;

            //-----------------------------------------------------------------------------------------------------
            const precision = this.calcOrderbook(res_data);
            const price_level = this.calPriceLevelUnit(base_price, this.target_fill_gap, precision[0], precision[1]);
            const price_percent = this.calPricePercentUnit(price_level, this.ask_mode_range, precision[1]);
            this.configData.price_to_askwall = `시작가(${price_level}) + 범위(${this.ask_mode_range})% = 벽 가격(${price_percent})`;

            this.is_price_to_wall1_loading = false;
        },
        async calcwall2(){
            this.is_price_to_wall2_loading = true;
            let base_price = 0
            if (this.target_price === -1){
                //티커 호출 --------------------------------------------------------------------------------------------
                // 심볼
                let symbol = ''
                if (this.configData.exchange === 'coinstore'){
                    symbol = `${this.configData.coin}${this.configData.pair}`;
                } else {
                    symbol = `${this.configData.coin}_${this.configData.pair}`; 
                }
                // 종가 exchange2로
                const ticker_1 = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/ticker/${this.select_server}/${symbol.toUpperCase()}`);

                let ticker_last = ''
                if (this.configData.exchange === 'bitget' || this.configData.exchange === 'coinstore') {
                    ticker_last = ticker_1.data.close;
                } else if (this.configData.exchange === 'gopax' || this.configData.exchange === 'lbank') {
                    ticker_last = ticker_1.data.price;
                } else if (this.configData.exchange === 'whitebit') {
                    ticker_last = ticker_1.data.last_price;
                } else if (this.configData.exchange === 'xt') {
                    ticker_last = ticker_1.data.c;
                } else if (this.configData.exchange === 'upbit') {
                    ticker_last = ticker_1.data.trade_price;
                } else if (this.configData.exchange === 'mexc' || this.configData.exchange === 'bingx') {
                    ticker_last = ticker_1.data.lastPrice;
                } else {
                    ticker_last = ticker_1.data.last;
                }
                base_price = ticker_last;
            //-----------------------------------------------------------------------------------------------------
            } else {
                base_price = this.target_price;
            }

            //오더북 호출 --------------------------------------------------------------------------------------------
            let symbol_ob = '';
            if (this.configData.exchange === 'okex'){
                symbol_ob = `${this.configData.coin.toUpperCase()}-${this.configData.pair.toUpperCase()}`;
            } else if (this.configData.exchange === 'coinstore' || this.configData.exchange === 'huobi' || this.configData.exchange === 'mexc' || this.configData.exchange === 'binance') {
                symbol_ob = `${this.configData.coin.toUpperCase()}${this.configData.pair.toUpperCase()}`
            } else if (this.configData.exchange === 'lbank') {
                symbol_ob = `${this.configData.coin}_${this.configData.pair}`;
            } else if (this.configData.exchange === 'bitkub') {
                symbol_ob = `${this.configData.pair.toUpperCase()}_${this.configData.coin.toUpperCase()}`;
            } else {
                symbol_ob = `${this.configData.coin.toUpperCase()}_${this.configData.pair.toUpperCase()}`;
            }
            
            let res
            if (this.configData.exchange === 'upbit' || this.configData.exchange === 'coinone'){
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}/0`);
            } else {
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}`);
            }
            let res_data = res.data;

            //-----------------------------------------------------------------------------------------------------
            const precision = this.calcOrderbook(res_data);
            const price_level = this.calPriceLevelUnit(base_price, (this.target_fill_gap * -1), precision[0], precision[1]);
            const price_percent = this.calPricePercentUnit(price_level, (this.bid_mode_range * -1), precision[1]);
            this.configData.price_to_bidwall = `시작가(${price_level}) - 범위(${this.bid_mode_range})% = 벽 가격(${price_percent})`;

            this.is_price_to_wall2_loading = false;
        },
        calcGapLevel(low, high, unit = null, unitRound = null) {
            // console.log(low)
            // console.log(high)
            // console.log(unit)
            // console.log(unitRound)
            return Math.round((high - low) * Math.pow(10, unitRound)) - 1;
        },
        calPricePercentUnit(price, percent, unitRound = null) {
            // console.log(price)
            // console.log(percent)
            // console.log(unitRound)
            const _price = price * (1 + (percent / 100));
            const roundedPrice = this.roundPrice(_price, unitRound);

            // price = this.roundPrice(parseFloat(price) + parseFloat(unit), unitRound);

            return roundedPrice;
        },
        async calcGap(){
            this.is_price_to_gap_loading = true;
            let base_price = 0
            if (this.target_price === -1){
                //티커 호출 --------------------------------------------------------------------------------------------
                // 심볼
                let symbol = ''
                if (this.configData.exchange === 'coinstore'){
                    symbol = `${this.configData.coin}${this.configData.pair}`;
                } else {
                    symbol = `${this.configData.coin}_${this.configData.pair}`; 
                }
                // 종가 exchange2로
                const ticker_1 = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/ticker/${this.select_server}/${symbol.toUpperCase()}`);

                let ticker_last = ''
                if (this.configData.exchange === 'bitget' || this.configData.exchange === 'coinstore') {
                    ticker_last = ticker_1.data.close;
                } else if (this.configData.exchange === 'gopax' || this.configData.exchange === 'lbank') {
                    ticker_last = ticker_1.data.price;
                } else if (this.configData.exchange === 'whitebit') {
                    ticker_last = ticker_1.data.last_price;
                } else if (this.configData.exchange === 'xt') {
                    ticker_last = ticker_1.data.c;
                } else if (this.configData.exchange === 'upbit') {
                    ticker_last = ticker_1.data.trade_price;
                } else if (this.configData.exchange === 'mexc' || this.configData.exchange === 'bingx') {
                    ticker_last = ticker_1.data.lastPrice;
                } else {
                    ticker_last = ticker_1.data.last;
                }
                base_price = ticker_last;
            //-----------------------------------------------------------------------------------------------------
            } else {
                base_price = this.target_price;
            }

            //오더북 호출 --------------------------------------------------------------------------------------------
            let symbol_ob = '';
            if (this.configData.exchange === 'okex'){
                symbol_ob = `${this.configData.coin.toUpperCase()}-${this.configData.pair.toUpperCase()}`;
            } else if (this.configData.exchange === 'coinstore' || this.configData.exchange === 'huobi' || this.configData.exchange === 'mexc' || this.configData.exchange === 'binance') {
                symbol_ob = `${this.configData.coin.toUpperCase()}${this.configData.pair.toUpperCase()}`
            } else if (this.configData.exchange === 'lbank') {
                symbol_ob = `${this.configData.coin}_${this.configData.pair}`;
            } else if (this.configData.exchange === 'bitkub') {
                symbol_ob = `${this.configData.pair.toUpperCase()}_${this.configData.coin.toUpperCase()}`;
            } else {
                symbol_ob = `${this.configData.coin.toUpperCase()}_${this.configData.pair.toUpperCase()}`;
            }
            
            let res
            if (this.configData.exchange === 'upbit' || this.configData.exchange === 'coinone'){
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}/0`);
            } else {
                res = await this.$http.get(`/api/v1/exchange2/${this.configData.exchange}/orderbook/${this.select_server}/${symbol_ob}`);
            }
            let res_data = res.data;

            //-----------------------------------------------------------------------------------------------------
            const precision = this.calcOrderbook(res_data);
            const price_level = this.calPriceLevelUnit(base_price, (this.target_fill_gap * 1), precision[0], precision[1]);
            const price_level_ = this.calPriceLevelUnit(base_price, (this.target_fill_gap * -1), precision[0], precision[1]);

            this.configData.price_to_gap = `시작가(매수):${price_level_}, 시작가(매도):${price_level}`;

            this.is_price_to_gap_loading = false;
        },
        calcOrderbook(orderbook) {
            let price_list = [];
            let price_round_list = [];
            let price, price_round, orderbook_pirce, price_str, zero_value, orderbook_index;

            if (this.configData.exchange === 'coinone' || this.configData.exchange === 'upbit'){
                orderbook_index = 15;
            } else {
                orderbook_index = 20;
            }

            for (let i = 0; i < orderbook_index; i++) {
                // a, 0
                if (this.configData.exchange === 'coinstore'){ 
                    orderbook_pirce = parseFloat(orderbook.a[i][0]);
                // asks, price
                } else if (this.configData.exchange === 'bithumb' || this.configData.exchange === 'bitmart' || this.configData.exchange === 'coinone' || this.configData.exchange === 'poloniex' || this.configData.exchange === 'upbit' || this.configData.exchange === 'probit'){
                    orderbook_pirce = parseFloat(orderbook.asks[i]['price']);
                // asks, 0
                } else {
                    orderbook_pirce = parseFloat(orderbook.asks[i][0]);
                }

                // 소수점 찾는 정규식
                const decimalRegex = /\.(\d*?)0*$/;

                // 정수 제외 소수점 이하 부분 추출
                const decimalPart = orderbook_pirce.toString().match(decimalRegex);

                if (decimalPart) {
                price_round = decimalPart[1].length;
                price = 10 ** (-1 * price_round);
                } else {
                price_str = orderbook_pirce.toString();
                zero_value = 0;
                for (let i = price_str.length - 1; i >= 0; i--) {
                    if (price_str[i] !== '0') {
                    zero_value = price_str.length - 1 - i;
                    break;
                    }
                }
                price_round = - zero_value
                price = 10 ** (-1 * price_round);
                }

                if (!isNaN(price)) {
                    price_list.push(price);
                    price_round_list.push(price_round);
                }
            }
            price = Math.min(...price_list);
            price_round = Math.max(...price_round_list);
            let roundedNumber = Math.round(price * (10 ** price_round)) / (10 ** price_round);

            return [roundedNumber, price_round];
        },
        calPriceLevelUnit(price, level, unit = null, unitRound = null) {            
            if (level === 0) {
                return price;
            }
            const isUp = level > 0 ? 1 : -1;
            const loop = Math.abs(level);
            for (let i = 0; i < loop; i++) {
                if (isUp === 1) {
                    price = this.roundPrice(parseFloat(price) + parseFloat(unit), unitRound);
                } else {
                    price = this.roundPrice(parseFloat(price) - parseFloat(unit), unitRound);
                }
            }
            
            return price;
        },
        roundPrice(value, decimals) {
            if (decimals < 0) {
                const multiplier = 10 ** (- decimals);
                return Math.round(value / multiplier) * multiplier;
            } else {
                return Number(parseFloat(value).toFixed(decimals));
            }
        },
        handleInput(event) {
            let value = event.target.value;
            // 정수와 소수 부분을 나누기
            let parts = value.split('.');
            // 정수 부분에 쉼표 추가
            let integerPart = parts[0].replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 소수 부분은 쉼표 없이 유지
            let decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, '') : '';
            // 소수점이 입력된 경우 처리
            if (value.includes('.')) {
                event.target.value = `${integerPart}.${decimalPart}`;
            } else {
                event.target.value = integerPart;
            }
            this.newItem1 = event.target.value;
        },
        handleInput2(event) {
            let value = event.target.value;
            // 정수와 소수 부분을 나누기
            let parts = value.split('.');
            // 정수 부분에 쉼표 추가
            let integerPart = parts[0].replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 소수 부분은 쉼표 없이 유지
            let decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, '') : '';
            // 소수점이 입력된 경우 처리
            if (value.includes('.')) {
                event.target.value = `${integerPart}.${decimalPart}`;
            } else {
                event.target.value = integerPart;
            }
            this.newItem2 = event.target.value;
        },
        handleInput3(event) {
            let value = event.target.value;
            // 정수와 소수 부분을 나누기
            let parts = value.split('.');
            // 정수 부분에 쉼표 추가
            let integerPart = parts[0].replace(/[^\d]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            // 소수 부분은 쉼표 없이 유지
            let decimalPart = parts[1] ? parts[1].replace(/[^\d]/g, '') : '';
            // 소수점이 입력된 경우 처리
            if (value.includes('.')) {
                event.target.value = `${integerPart}.${decimalPart}`;
            } else {
                event.target.value = integerPart;
            }
            this.newItem3 = event.target.value;
        },
        areBothItemsEntered() {
            // Check if both input fields have value
            return this.newItem1.trim() !== '' && this.newItem2.trim() !== '' && this.newItem3.trim() !== ''
        },
        addItem() {
            if (this.areBothItemsEntered()) {
                this.items.push([this.newItem1, this.newItem2, this.newItem3])
                // this.newItem1 = ''
                // this.newItem2 = ''
                // this.newItem3 = ''
                // this.sortDataByItem1()
            }

        },
        sortDataByItem1() {
            this.items.sort(function(a, b) {
                return a[0] - b[0]
            }) 
        },
        deleteItem(index) {
            // const startIndex = index;
            this.items.splice(index, 1);
        },
        createEdit(record){
            this.is_edit = true
            this.configData = record
            this.configDetailData = record.config
            this.select_server = record.server
            this.select_telegram_id = record.telegram_id
            this.select_telegram_id2 = record.telegram_id2
            this.telegram_select_active = record.telegram_select
            this.precision_price = record.precision_price_
            this.ask_mode_wall = record.ask_mode_wall_
            this.ask_mode_range = record.ask_mode_range_
            this.ask_mode_price = record.ask_mode_price_
            this.bid_mode_wall = record.bid_mode_wall_
            this.bid_mode_range = record.bid_mode_range_
            this.bid_mode_price = record.bid_mode_price_
            this.target_price = record.config.mode.target_price_ 
            this.fill_total_price = record.config.mode.fill_total_price_ 
            this.target_fill_gap = record.config.mode.target_fill_gap_ 
            this.fill_range_percent = record.config.mode.fill_range_percent_
            this.items = record.config.mode.amounts
            this.is_opposite_allowance = record.is_opposite_allowance_
            this.opposite_upper_limit = record.opposite_upper_limit_
            this.opposite_lower_limit = record.opposite_lower_limit_
            this.order_buy_amount_out_limit = record.order_buy_amount_out_limit_
            this.order_sell_amount_out_limit = record.order_sell_amount_out_limit_
            this.changeExchangeSelect(record.exchange)
        },
        createAdd(){
            if(this.is_edit === true) {
                this.is_edit = false
                this.clearForm()
            }
        },
        clearForm(){
            this.configData = {}
            this.configDetailData = {}
            this.configOptionPrecisionData = {}
            this.amounts = []
        }

    },
    
}
</script>
<style lang="scss">

.ant-form-item {
    margin-bottom: 5px;
}
    
.item-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

</style>